import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConstantService} from "../constants/constant.service";
import {GoogleTagManagerService} from "angular-google-tag-manager";

@Injectable({
  providedIn: 'root'
})
export class ContactDetailDialogService {
  isModalOpen = false;
  contentId: string | null;
  step = 0;
  contactInformationSubmitted = false;
  constructor(private http: HttpClient, private constantService: ConstantService,
              private gtm: GoogleTagManagerService) { }

  isContactDetailWorkflow(){
    return this.step == 0;
  }
  isNpsWorkflow() {
    return this.step == 1;
  }
  onDismiss() {
    this.isModalOpen = false;
  }

  private onOpen() {
    this.isModalOpen = true;
  }
  openFeedbackFlow(){
    this.contactInformationSubmitted = false;
    this.setNpsWorkflow();
    this.onOpen();
  }

  setNpsWorkflow() {
    this.step = 1;
  }

  openContactDetailFlow(){
    this.step = 0;
    this.onOpen();
    const gtmTag = {
      event: 'open-contact-detail-dialog',
      pageName: 'main'
    }
    this.gtm.pushTag(gtmTag);
  }
  submit(contactId: string, name:string | null, email: string | null, location: string | null){
    let objectObservable = this.http.post(this.constantService.endpoint+'alpha/api/contact-info/',{
      contentId: this.contentId,
      contactId,
      name,
      email,
      location
    });
    const gtmTag = {
      event: 'submit-contact-detail-dialog',
      pageName: 'main'
    }
    this.gtm.pushTag(gtmTag);
    return objectObservable;
  }

  sendNps(contactId: string, email: string, score: number, comment: string, consentToBeContacted: boolean){
    return this.http.post(this.constantService.endpoint+'alpha/api/nps/', {
      contactId,
      email,
      score,
      comment,
      consentToBeContacted
    });
  }
}
