<p-button-pure type="button" [theme]="'dark'" [aria]="{ 'aria-haspopup': 'dialog' }" (click)="onOpen()">Find charging stations</p-button-pure>
<p-modal [open]="isModalOpen" (dismiss)="onDismiss()" [aria]="{ 'aria-label': 'Some Heading' }">
  <div class="d-flex align-items-end">
    <form [formGroup]="profileForm">
      <p-text-field-wrapper [label]="'Enter your zip code'" [hideLabel]="false">
        <input [type]="'text'" [name]="'zip-code'" formControlName="zip"/>
      </p-text-field-wrapper>
      <p-button (click)="onSearch()" [disabled]="profileForm.controls.zip.invalid">Search</p-button>
      <div *ngIf="profileForm.controls.zip.invalid && (profileForm.controls.zip.dirty || profileForm.controls.zip.touched)"
           class="alert alert-info">
        <div *ngIf="profileForm.controls.zip.errors?.['singaporeZip']">
          Is this a singapore zip code? Please check your input.
        </div>
      </div>
      <div *ngIf="requestFailed" class="alert alert-danger">
        We could not find any location for that zip code? Please check your input and try again.
      </div>
    </form>

  </div>
  <div *ngIf="locations && locations.length > 0 && (apiLoaded | async)">
    <google-map
      height="600px"
      width="66vw"
      [center]="center"
      [zoom]="zoom"
    >
      <map-marker *ngFor="let position of locations" [position]="position" [options]="markerOptions"/>
    </google-map>
  </div>
</p-modal>
