import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ContentBlock} from "../../../../interfaces/contentBlock";

@Component({
  selector: 'app-multi-template',
  templateUrl: './multi-template.component.html',
  styleUrls: ['./multi-template.component.css']
})
export class MultiTemplateComponent {
  @Input() items: ContentBlock[];
  @Output() userClickedModel = new EventEmitter<string>();

  modelClicked(item: ContentBlock) {
    this.userClickedModel.emit(item.contentId)
  }
}
