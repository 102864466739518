import { Component } from '@angular/core';
import {DisclaimerService} from "../../services/disclaimer.service";
import {ConstantService} from "../../constants/constant.service";

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.css']
})
export class DisclaimerComponent {
  constructor(public disclaimerService: DisclaimerService, public constantService: ConstantService) {

  }

  onAccept() {
    this.disclaimerService.acceptTerms();
  }
}
