<div class="d-flex justify-content-between align-items-end footer">
  <div class="d-flex justify-content-center align-items-end">
    <div class="white-text" [ngClass]="constantService.configuration.subtitleClassName">
      <a class="white-text" href="https://www.tripleai.co" target="_blank">Powered by Triple AI</a></div>
    <div class="white-text" [ngClass]="constantService.configuration.subtitleClassName">
      <a class="white-text"
         [ngClass]="constantService.configuration.subtitleClassName"
         [href]="constantService.configuration.termsAndConditionsUrl"
         target="_blank">
        Terms and Conditions
      </a>
    </div>
  </div>
  <div>
    <app-charging-station-finder hidden></app-charging-station-finder>
    <app-contact-details-dialog></app-contact-details-dialog>
  </div>

  <app-contact-detail-button></app-contact-detail-button>
</div>
