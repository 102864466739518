<div  class="row row-cols-2">
  <div class="col d-flex flex-column justify-content-center align-items-center" *ngFor="let item of items;" (click)="modelClicked(item)">
    <div class="glas-style glas-style-border p-3" >
      <div
        class="model-card m-1 "
        [style.background-image]="'url(' + item.cardBackgroundImageUrl + ')'"
      >
      </div>
    </div>
  </div>
</div>
