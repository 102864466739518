import {AppConfiguration} from "../../app/interfaces/app-configuration";

export const defaultAppConfiguration: AppConfiguration = {
  saveTermsAndConditionSettings: false,
  termsAndConditionsUrl: "https://www.tripleai.co/terms-and-conditions-selene-sg#sg.tripleai.co",
  useCustomContentBlock: false,
  backgroundImageClass: "background-image-porsche",
  navButtonClass: "contact-button-nav",
  contactDetailCtaButtonLabel: "Get in Touch",
  navContactButtonLabel: "Contact us",
  navLogSrc: "assets/logo--porsche.svg",
  avatarBorderRadius: 20,
  avatarControlsHeight: 32,
  avatarControlsWidth: 54,
  inputFieldClassName: 'porsche-input',
  chatOverlayClassName: 'notDefined',
  subtitleClassName: 'porsche-font',
  useCustomDialogs: false,
  privacyPolicyUrl: 'https://www.porsche.com/singapore/en/privacy-policy/#:~:text=We%20will%20not%20use%20your,data%20we%20hold%20about%20you.&text=display%20advertising%20content%20that%20is,outside%20of%20the%20Porsche%20websites',
  welcomeImageSrc: 'assets/bg.webp',
  disableImageSelectionSync: false,
  avatarName: 'SELENE',
  useCustomLandingPage: false
}
