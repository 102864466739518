import {Component, HostListener, OnInit} from '@angular/core';
import {MobileSizeService} from "./services/mobile-size.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'porsche-eve-demo';

  constructor(public mobileSizeService: MobileSizeService) {
    this.checkSizeWarning();
  }

  ngOnInit(): void {
    }

  @HostListener('window:resize', ['$event'])
  checkSizeWarning() {
    this.mobileSizeService.isMobileSize = window.innerHeight >= window.innerWidth;
  }
}
