import { Component } from '@angular/core';
import {ContactDetailDialogService} from "../../services/contact-detail-dialog.service";
import {ConstantService} from "../../constants/constant.service";
import {Router} from "@angular/router";
import {MobileSizeService} from "../../services/mobile-size.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  constructor(public contactDetailDialogService: ContactDetailDialogService,
              public constantService: ConstantService,
              public router: Router,
              public mobileSizeService: MobileSizeService) {
  }
  contactUsClicked() {
    this.contactDetailDialogService.openContactDetailFlow();
  }
  logoClicked(){
    // angular internal navigation would not reset services and components resulting in fucked up soulmachines sdk init
    window.location.href = window.location.origin;
  }
}
