<div class="avatar-wrapper d-flex flex-column justify-content-between align-items-center m-4"
      [style.width.vw]="30"
      [style.border-radius.px]="constantService.configuration.avatarBorderRadius">
  <div class="avatar-video loader-overlay" *ngIf="avatarService.showIsProcessing"
       [style.border-radius.px]="constantService.configuration.avatarBorderRadius">
    <div>
      <div class="loader-container">
        <div class="loader"></div>
      </div>
      <div class="loader-font">Thinking...</div>
    </div>

  </div>
  <div *ngIf="avatarService.isVideoMuted" class="d-flex justify-content-center align-items-center avatar-video" style="z-index: 10;">
    <button class="muted-indicator"  (click)="mutedClicked($event)">
      <div>
        <p-icon [theme]="'dark'" [size]="'small'" [name]="'volume-off'"
                [aria]="{ 'aria-label': 'Volume Off icon' }"></p-icon>
      </div>
      <div>{{constantService.configuration.avatarName}} is muted</div>
    </button>
  </div>
  <div class="d-flex justify-content-start align-items-start w-100"
       style="gap: 16px; opacity: 0.99; z-index: 11; margin-left: 12px; margin-top: 8px;">
    <button class="avatar-control"
            [style.width.px]="constantService.configuration.avatarControlsWidth"
            [style.height.px]="constantService.configuration.avatarControlsHeight"

            (click)="repeatQuestion($event)">
      <p-icon [theme]="'dark'" [size]="'small'" [name]="'refresh'"
              [aria]="{ 'aria-label': 'Replay icon' }"></p-icon>
    </button>
    <button class="avatar-control"
            [style.width.px]="constantService.configuration.avatarControlsWidth"
            [style.height.px]="constantService.configuration.avatarControlsHeight"
            (click)="mutedClicked($event)" *ngIf="!avatarService.isVideoMuted">
      <!-- <p-icon [theme]="'dark'" [size]="'small'" [name]="'volume-up'"
              [aria]="{ 'aria-label': 'Volume Up icon' }"></p-icon>-->
      <p-icon [theme]="'dark'" [size]="'small'" [name]="'volume-off'"
              [aria]="{ 'aria-label': 'Volume Off icon' }"></p-icon>
    </button>
  </div>
  <div class="d-flex justify-content-center align-items-center">
    <video id="sm-video" class="avatar-video" [style.border-radius.px]="constantService.configuration.avatarBorderRadius"></video>
  </div>

  <div class="w-100 chat-overlay"
       [style.border-bottom-left-radius.px]="constantService.configuration.avatarBorderRadius"
       [style.border-bottom-right-radius.px]="constantService.configuration.avatarBorderRadius">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <div class="d-flex justify-content-center" *ngIf="avatarService.showReconnectButton">
        <p-button-pure type="button" [theme]="'dark'"
                       (click)="reconnect()">Reconnect
        </p-button-pure>
      </div>
      <div class="overflow-auto w-100 justify-content-center align-items-center">
        <div class="text-white m-2">
          <div class="text-center justify-content-center align-items-center overflow-visible"
               [ngClass]="constantService.configuration.subtitleClassName"
               *ngIf="!avatarService.showIsProcessing && avatarService.avatarIsSpeaking"
               style="padding-top: 16px; min-height: 89px;max-height: 89px;"
          >
            <div class="subtitle" style="opacity: 0.99; position: relative; margin-top: 33px;margin-left: auto; margin-right: auto;"  [style.top.px]="avatarService.highlightTextTopMargin">
              {{ avatarService.scrollingHighlightText }}
            </div>
          </div>
          <div *ngIf="!avatarService.avatarIsSpeaking" style="margin-top: 16px;">
            <app-answer-button-recommendation
              [currentConfig]="currentConfig"
              [showIsProcessing]="avatarService.showIsProcessing"
              (sendButtonMessageClicked)="sendButtonMessage($event.text, $event.guidedStep)"
            ></app-answer-button-recommendation>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 p-2 position-relative" style="max-width: 30vw;"
    >
      <app-text-input [(userMessage)]="userMessage"
                      (sendUserMessageClicked)="userEnteredText($event)"></app-text-input>
    </div>
  </div>
</div>
