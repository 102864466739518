import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {DbService} from "../../services/db.service";
import {ConstantService} from "../../constants/constant.service";
import {GoogleTagManagerService} from "angular-google-tag-manager";

@Component({
  selector: 'app-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.css'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        right: '0px'
      })),
      state('closed', style({
        right: '-400px'
      })),
      transition('open => closed', [
        animate('0.1s')
      ]),
      transition('closed => open', [
        animate('0.1s')
      ]),
    ]),
    trigger('growShrink', [
      // ...
      state('grow', style({
        height: '800px'
      })),
      state('shrink', style({
        height: '200px'
      })),
      transition('grow => shrink', [
        animate('0.5s')
      ]),
      transition('shrink => grow', [
        animate('0.5s')
      ]),
    ]),
  ]
})
export class MainViewComponent {

  contactId: string;


  constructor(private route: ActivatedRoute,
              public db: DbService,
              public constantService: ConstantService,
              private gtm: GoogleTagManagerService) {
    // @ts-ignore
    this.contactId = this.route.snapshot.paramMap.params.id;
    this.db.connect(this.contactId);
    const gtmTag = {
      event: 'start-main-view',
      pageName: 'main'
    }
    this.gtm.pushTag(gtmTag);

  }
}
