import { Injectable } from '@angular/core';
import {ContentBlock} from "../interfaces/contentBlock";

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }
  isVideo(item: ContentBlock) {
    return item?.cardBackgroundImageUrl?.includes('.mp4');
  }
}
