import {Component} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {catchError, map, Observable} from "rxjs";
import {ConstantService} from "../../constants/constant.service";
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";

@Component({
  selector: 'app-charging-station-finder',
  templateUrl: './charging-station-finder.component.html',
  styleUrls: ['./charging-station-finder.component.css']
})
export class ChargingStationFinderComponent {
  center: google.maps.LatLngLiteral = {lat: 1.32737, lng: 103.971325};
  zoom = 14;
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = [{lat: 1.32737, lng: 103.971325}];
  public apiLoaded: Observable<boolean>;
  locations?: { lng: number, lat: number }[]
  isModalOpen = false;
  profileForm = new FormGroup({
    zip: new FormControl('',[
      Validators.required,
      singaporeZipValidator(/^\d{6}$/i)])
  });
  zipCode: number;
  private heroForm: FormGroup<{ name: FormControl<any> }>;
  public requestFailed = false;

  constructor(private http: HttpClient, private constantService: ConstantService) {
    // If you're using the `<map-heatmap-layer>` directive, you also have to include the `visualization` library
    // when loading the Google Maps API. To do so, you can add `&libraries=visualization` to the script URL:
    // https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=visualization

    this.apiLoaded = http.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyAnE8HcD9epqoVSYIdaDXYqog9wy6H7UwQ', 'callback')
      .pipe(
        map(() => true),
        catchError(err => {
          throw 'error in source. Details: ' + err;
        }),
      );
    this.profileForm.controls.zip.registerOnChange(() => {
      this.requestFailed = false;
    })
  }

  onOpen() {
    this.isModalOpen = true;
  }

  onDismiss() {
    this.isModalOpen = false;
  }

  onSearch() {
    this.http.get(this.constantService.endpoint + 'alpha/charging-stations/' + this.profileForm.controls.zip.value + '/').subscribe((res: any) => {
      this.center = {lat: Number(res.center.lat), lng: Number(res.center.lng)};
      this.locations = res.locations.map((x: { lat: string, lng: string }) => {
        return {lat: Number(x.lat), lng: Number(x.lng)}
      });
    }, error => {
      this.requestFailed = true;
    })
  }
}
export function singaporeZipValidator(nameRe: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const forbidden = nameRe.test(control.value);
    return forbidden ? null : {singaporeZip: {value: control.value}};
  };
}
