import {Component, Input} from '@angular/core';
import {ContentBlock} from "../../../../interfaces/contentBlock";
import {AvatarService} from "../../../../services/avatar.service";

@Component({
  selector: 'app-duo-template',
  templateUrl: './duo-template.component.html',
  styleUrls: ['./duo-template.component.css']
})
export class DuoTemplateComponent {
  private _items: ContentBlock[];
  get items(): ContentBlock[] {
    return this._items;
  }
  @Input()
  set items(value: ContentBlock[]) {
    this._items = value;
    this.userHasSelectedImage = false;
  }


  selectedImageIndex: number = 0;
  scrollLeft: number = 0;
  userHasSelectedImage = false;
  private galleryShiftInterval: number;
  constructor(private avatarService: AvatarService) {
    /*
    this.galleryShiftInterval = setInterval(() => {
      const strings = this.items[0].imageUrls;
      if (strings && strings.length - 1 > this.selectedImageIndex ) {
        this.selectedImageIndex++;
      } else {
        this.selectedImageIndex = 0;
      }

    }, 5000)
     */
  }

  userSelectedContentBlock($event: string) {
    this.avatarService.userSelectedCardButton($event);
  }

  onSelectedIamgeIndexChange($event: number) {
    this.selectedImageIndex = $event;
    this.userHasSelectedImage = true;
    clearInterval(this.galleryShiftInterval)
  }
}
