import { Component } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {ConstantService} from "../../constants/constant.service";
import {MobileSizeService} from "../../services/mobile-size.service";

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class StartComponent {
  constructor(private http: HttpClient,
              private router: Router,
              public constants: ConstantService,
              public mobileSizeService: MobileSizeService) {
  }

  public navigateToStart(type: string) {
    // local
    //const url = 'http://localhost:8000/start/';
    // debug
    //const url = 'https://0ea7-2003-cd-ef47-7400-20df-a45b-27fd-540f.ngrok-free.app/start/';
    // prod
    const url = this.constants.endpoint+'start/';
    this.http.post(url, {type}).subscribe((res: any) => {
      this.router.navigate(['s', res.session]);
    });
  }
}
