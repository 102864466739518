import { Component } from '@angular/core';
import {ConstantService} from "../../constants/constant.service";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";

@Component({
  selector: 'app-imidiate-session-start',
  templateUrl: './immediate-session-start.component.html',
  styleUrls: ['./immediate-session-start.component.css']
})
export class ImmediateSessionStartComponent {
  constructor(private constants: ConstantService, private http: HttpClient, private router: Router) {
    const url = this.constants.endpoint + 'start/';
    this.http.post(url, {type: 'guided'}).subscribe((res: any) => {
      this.router.navigate(['s', res.session]);
    });
  }
}
