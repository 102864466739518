<div>
  <div class="d-flex justify-content-end text-white" *ngIf="constantsService.configuration.useCustomDialogs">
    <div class="close-button" (click)="contactDetailDialogService.onDismiss()">
      x
    </div>
  </div>
  <div>
    <div *ngIf="contactDetailDialogService.contactInformationSubmitted && !isSubmitted">
      <div class="nps-headline" style="margin-bottom: 24px;"
           [class.white-font]="constantsService.configuration.useCustomDialogs">
        Thank you for your contact information!
      </div>
      <div class="nps-subtitle" style="margin-bottom: 32px;"
           [class.white-font]="constantsService.configuration.useCustomDialogs">Share your feedback
      </div>
    </div>
    <div *ngIf="!isSubmitted">
      <div *ngIf="!contactDetailDialogService.contactInformationSubmitted" class="nps-headline nps-headline-spacing"
           [class.white-font]="constantsService.configuration.useCustomDialogs">
        Share your Feedback
      </div>
      <div class=""></div>
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="nps-subtitle" style="margin-bottom: 24px;"
             [class.white-font]="constantsService.configuration.useCustomDialogs">
          How likely are you to recommend {{ avatarName }} to your friends?
        </div>
        <div class="d-flex" style="gap: 8px;">
          <div class="nps-button" *ngFor="let number of npsButtons"
               [class.black-border]="!constantsService.configuration.useCustomDialogs"
               [class.white-font]="constantsService.configuration.useCustomDialogs"
               (click)="selectedNpsScore = number;"
               [class.nps-button-selected]="selectedNpsScore == number && constantsService.configuration.useCustomDialogs"
               [class.nps-button-selected-porsche]="selectedNpsScore == number && !constantsService.configuration.useCustomDialogs"
          >
            {{ number }}
          </div>
        </div>
        <div class="d-flex justify-content-between w-100 mt-1 mb-2" [class.white-font]="constantsService.configuration.useCustomDialogs">
          <div>not likely at all</div>
          <div>extremely likely</div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selectedNpsScore && !isSubmitted">
    <div class="nps-headline nps-headline-spacing" style="margin-top: 24px;" [class.white-font]="constantsService.configuration.useCustomDialogs">
      Leave a Comment
    </div>
    <div class="textarea-headline" [class.transparent-font]="constantsService.configuration.useCustomDialogs">
      How could we improve your experience with {{ avatarName }}?
    </div>

    <div *ngIf="constantsService.configuration.useCustomDialogs">
      <textarea class="custom-modal-input comment-text w-100"
                [(ngModel)]="comment"
                [ngModelOptions]="{standalone: true}"></textarea>
    </div>
    <div *ngIf="!constantsService.configuration.useCustomDialogs">
      <p-textarea-wrapper>
              <textarea
                class="comment-text"
                [(ngModel)]="comment"
                [ngModelOptions]="{standalone: true}"></textarea>
      </p-textarea-wrapper>
    </div>
    <div style="margin-top: 16px;">
      <div *ngIf="constantsService.configuration.useCustomDialogs" >
        <input class="custom-modal-input w-100" placeholder="Email or Phone" [type]="'email'" [(ngModel)]="email"
               [ngModelOptions]="{standalone: true}"/>
      </div>
      <div *ngIf="!constantsService.configuration.useCustomDialogs" class="w-100">
        <p-text-field-wrapper [label]="'Email or Phone'" [hideLabel]="false">
          <input class="custom-modal-input" placeholder="Email or Phone" [type]="'email'" [(ngModel)]="email"
                 [ngModelOptions]="{standalone: true}"/>
        </p-text-field-wrapper>
        <div style="margin-top: 16px;">
          <p-checkbox-wrapper>
            <span slot="label" id="some-label-id">I'm open to a follow-up call with a Porsche employee</span>
            <input type="checkbox" [(ngModel)]="userConsentToBeContacted"
                   [ngModelOptions]="{standalone: true}"/>
          </p-checkbox-wrapper>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center" style="margin-top: 24px;">
      <div *ngIf="constantsService.configuration.useCustomDialogs" class="submit-button" (click)="onSubmit()">Submit
      </div>
      <p-button *ngIf="!constantsService.configuration.useCustomDialogs" [icon]="'arrow-right'" (click)="onSubmit()">
        Submit
      </p-button>
    </div>
  </div>
  <div *ngIf="isSubmitted">
    <div class="nps-headline nps-headline-spacing text-center"
         [class.white-font]="constantsService.configuration.useCustomDialogs">
      Thank you for your Feedback!
    </div>
    <div class="textarea-headline text-center" [class.white-font]="constantsService.configuration.useCustomDialogs">
      We will continue developing and improving {{ avatarName }}. <br>Please visit us again soon!
    </div>
    <div class="d-flex justify-content-center">
      <div *ngIf="constantsService.configuration.useCustomDialogs" class="submit-button"
           (click)="contactDetailDialogService.onDismiss()">Continue talking to {{ avatarName }}
      </div>
      <p-button *ngIf="!constantsService.configuration.useCustomDialogs" [icon]="'arrow-right'"
                (click)="contactDetailDialogService.onDismiss()">Continue talking to {{ avatarName }}
      </p-button>
    </div>
  </div>
</div>
