import {Component, Input} from '@angular/core';
import {ContentBlock} from "../../../../interfaces/contentBlock";

@Component({
  selector: 'app-mobile-single-template',
  templateUrl: './mobile-single-template.component.html',
  styleUrls: ['./mobile-single-template.component.css']
})
export class MobileSingleTemplateComponent {
  @Input() items: ContentBlock[];
}
