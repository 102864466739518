<div class="row " *ngIf="contentBlocks">
  <div class="col" *ngIf="!mobileSizeService.isMobileSize">
    <div class="d-flex align-content-center" *ngIf="contentBlocks">
      <app-single-template *ngIf="contentBlocks.length == 1" [items]="contentBlocks"></app-single-template>
      <app-duo-template *ngIf="contentBlocks.length == 2" [items]="contentBlocks" ></app-duo-template>
    </div>
  </div>
  <div *ngIf="mobileSizeService.isMobileSize">
    <app-mobile-single-template *ngIf="contentBlocks.length == 1" [items]="contentBlocks"></app-mobile-single-template>
    <app-mobile-duo-template *ngIf="contentBlocks.length == 2" [items]="contentBlocks" ></app-mobile-duo-template>
  </div>
</div>
