<div *ngIf="currentConfig && !showIsProcessing && !mobileSizeService.isMobileSize" class="w-100 d-flex align-items-end flex-column">
  <div *ngIf="!constantService.configuration.useCustomDialogs" class="w-100">
    <p-button [theme]="'dark'" *ngFor="let btn of currentConfig.buttons;  let i = index"
              class="w-100 m-1 pt-1 ps-1 pe-1 pb-1"
              (click)="sendButtonMessage(btn)">
      {{ btn.text }}
    </p-button>
  </div>
  <div *ngIf="constantService.configuration.useCustomDialogs" class="w-100 d-flex" style="gap:8px;" [class.flex-column]="!mobileSizeService.isMobileSize">
    <div *ngFor="let btn of currentConfig.buttons;  let i = index"
         class="custom-button w-100 d-flex"
         [class.flex-column]="!mobileSizeService.isMobileSize"
         (click)="sendButtonMessage(btn)">
      {{ btn.text }}
    </div>
  </div>
</div>

<div *ngIf="currentConfig && !showIsProcessing && mobileSizeService.isMobileSize" class="scrollable-container w-100" style="overflow-x: auto; max-width: calc(100vw - 32px); ">
  <div *ngIf="!constantService.configuration.useCustomDialogs" class="d-flex flex-nowrap">
    <p-button [theme]="'dark'" *ngFor="let btn of currentConfig.buttons; let i = index"
              class="m-1 pt-1 ps-1 pe-1 pb-1 no-wrap-text"
              (click)="sendButtonMessage(btn)">
      {{ btn.text }}
    </p-button>
  </div>
  <div *ngIf="constantService.configuration.useCustomDialogs" class="d-flex flex-nowrap" style="gap:8px;">
    <div *ngFor="let btn of currentConfig.buttons; let i = index"
         class="custom-button no-wrap-text"
         (click)="sendButtonMessage(btn)">
      {{ btn.text }}
    </div>
  </div>
</div>
