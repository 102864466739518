import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DialogConfig} from "../../interfaces/dialog.config";
import {AnswerButton} from "../../interfaces/answer.button";
import {ContactDetailDialogService} from "../../services/contact-detail-dialog.service";
import {ConstantService} from "../../constants/constant.service";
import {MobileSizeService} from "../../services/mobile-size.service";

@Component({
  selector: 'app-answer-button-recommendation',
  templateUrl: './answer-button-recommendation.component.html',
  styleUrls: ['./answer-button-recommendation.component.css']
})
export class AnswerButtonRecommendationComponent {
  @Input() currentConfig: DialogConfig;
  @Input() showIsProcessing: boolean;
  @Output() sendButtonMessageClicked = new EventEmitter<AnswerButton>();

  constructor(private contactDetailDialogService: ContactDetailDialogService,
              public constantService: ConstantService,
              public mobileSizeService: MobileSizeService) {
  }
  sendButtonMessage(btn: AnswerButton) {
    switch (btn.actionType){
      case 'openContactForm':
        this.contactDetailDialogService.openContactDetailFlow();
        return
      case 'link':
        if (btn.href) {
          window.open(btn.href,'_blank');
        }
        return;
      default:
        this.sendButtonMessageClicked.emit(btn);
    }
  }
}
