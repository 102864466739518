import { Component } from '@angular/core';
import {ConstantService} from "../../constants/constant.service";
import {MobileSizeService} from "../../services/mobile-size.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  constructor(
    public constantService: ConstantService,
    public mobileSizeService: MobileSizeService
  ) {
  }
  public privacyPolicyUrl = "https://www.porsche.com/singapore/en/privacy-policy/#:~:text=We%20will%20not%20use%20your,data%20we%20hold%20about%20you.&text=display%20advertising%20content%20that%20is,outside%20of%20the%20Porsche%20websites"
}
