import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter, HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {ContentBlock} from "../../../../interfaces/contentBlock";
import {HelperService} from "../../../../services/helper.service";
import {ContentBlockType} from "../../../../enums/content-block-type";
import {ContactDetailDialogService} from "../../../../services/contact-detail-dialog.service";
import {ConstantService} from "../../../../constants/constant.service";
import {MobileSizeService} from "../../../../services/mobile-size.service";

@Component({
  selector: 'app-content-block',
  templateUrl: './content-block.component.html',
  styleUrls: ['./content-block.component.css']
})
export class ContentBlockComponent implements AfterViewInit, AfterContentInit {
  private _scrollLeft: number;
  private _selectedImageIndex: number | undefined;
  private readonly _gap = 16;
  private readonly _padding: 16 | 24 = 16;
  private readonly _smallLayoutBottomAreaHeight = 178;
  private readonly _wideLayoutBottomAreaLayoutAndHeader = 208;
  private _item: ContentBlock;

  public windowsVerticalScrollbarPrevention = 20;
  public baseDimensionHeight: number = 0;
  public baseDimensionWidth: number = 0;

  get item(): ContentBlock {
    return this._item;
  }
  @Input()
  set item(value: ContentBlock) {
    this._item = value;
    if (value){
      this.mainImageLink = this.getBackgroundImageUrl(value);
    }
  }

  get scrollLeft(): number {
    return this._scrollLeft;
  }
  @Input()
  set scrollLeft(value: number){
    this._scrollLeft = value;
    if (this.galleryWrapper && this.galleryWrapper.nativeElement.scrollLeft != value) {
      this.galleryWrapper.nativeElement.scrollLeft = value;
    }
  }

  @Output()
  scrollLeftChange: EventEmitter<number> = new EventEmitter<number>;
  get selectedImageIndex(): number | undefined {
    return this._selectedImageIndex;
  }
  @Input()
  set selectedImageIndex(value: number) {
    this._selectedImageIndex = value;
    if (this.item.imageUrls) {
      this.mainImageLink = this.item.imageUrls[value];
    }
  }
  @Output() selectedImageIndexChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() userSelected = new EventEmitter<string>();
  @Input() isWide = false;

  @ViewChild("imageArea")
  imageAreaElement: ElementRef;

  @ViewChild("galleryWrapper")
  galleryWrapper: ElementRef;

  public mainImageLink: string;
  public imageAreaHeight: number = 0;
  public imageAreaWidth: number = 0;
  public mainImageWidth: number = 0;
  public mainImageHeight: number = 0;
  public imageGalleryWidth: number = 0;
  public imageGalleryHeight: number;
  public imageGalleryItemHeight: number = 0;
  public imageGalleryItemWidth: number;


  constructor(public helper: HelperService,
              public contactDetailDialogService: ContactDetailDialogService,
              private cd: ChangeDetectorRef,
              public constantService: ConstantService,
              public mobileSizeService: MobileSizeService) {
    this._padding = this.getSidePadding();

  }

  ngAfterContentInit(): void {
  }



  ngAfterViewInit(): void {
    if (this.imageAreaElement) {
      this.calculateDimensions();


      this.cd.detectChanges();
    }
  }



  @HostListener('window:resize', ['$event'])
  private calculateDimensions() {
    this.baseDimensionHeight = this.mobileSizeService.isMobileSize
      ? window.innerHeight * 0.7
      : window.innerHeight * 0.7;

    if (this.isWide) {
      this.baseDimensionWidth = this.mobileSizeService.isMobileSize
        ? window.innerWidth - 24
        : window.innerWidth * 0.6;
      this.imageAreaHeight = this.baseDimensionHeight - this._wideLayoutBottomAreaLayoutAndHeader;
      this.imageAreaWidth = this.baseDimensionWidth - (this._padding * 2);
      if ((this.imageAreaWidth - 100) / this.imageAreaHeight >= 16 / 9) {
        this.mainImageWidth = this.imageAreaHeight * 16 / 9;
        this.mainImageHeight = this.imageAreaHeight;
        this.imageGalleryWidth = this.imageAreaWidth - this.mainImageWidth - this._gap;
        this.imageGalleryItemHeight = this.imageGalleryWidth * 9 / 16;
      } else {
        this.imageGalleryWidth = 100;
        this.imageGalleryItemHeight = this.imageGalleryWidth * 9 / 16;
        this.mainImageWidth = this.imageAreaWidth - this.imageGalleryWidth - this._gap;
        this.mainImageHeight = this.mainImageWidth * 9 / 16;
      }

    } else {
      this.baseDimensionWidth = this.mobileSizeService.isMobileSize
        ? window.innerWidth - 48
        : window.innerWidth * 0.3;
      this.imageAreaHeight = this.baseDimensionHeight - this._smallLayoutBottomAreaHeight;
      this.imageAreaWidth = this.baseDimensionWidth;
      this.mainImageWidth = this.imageAreaWidth;
      this.mainImageHeight = this.imageAreaWidth * 9 / 16;
      this.imageGalleryHeight = this.imageAreaHeight - this.mainImageHeight - 16;
      this.imageGalleryItemWidth = this.imageGalleryHeight * 16 / 9;
    }
  }

  protected readonly ContentBlockType = ContentBlockType;


  openContactDetailDialog() {
    if (this._item) {
      this.contactDetailDialogService.contentId = this._item.contentId;
    }
    this.contactDetailDialogService.openContactDetailFlow()
  }

  getBackgroundImageUrl(item: ContentBlock) {
    if (item.cardBackgroundImageUrl) {
      return item.cardBackgroundImageUrl;
    }
    if (item && item.imageUrls && item.imageUrls?.length > 0) {
      return item.imageUrls[0]
    }
    return ''
  }

  getSidePadding() {
    return this.constantService.configuration.useCustomContentBlock ? 24 : 16
  }

  onImageGallerySelected(image: string) {
    this.mainImageLink = image;
    if (!this.constantService.configuration.disableImageSelectionSync) {
      let index = this.item.imageUrls?.indexOf(image);
      this._selectedImageIndex = index;
      this.selectedImageIndexChange.emit(index);
    }
  }
  onScroll(){
    if (this.galleryWrapper && !this.constantService.configuration.disableImageSelectionSync) {
      this.scrollLeftChange.emit(this.galleryWrapper.nativeElement.scrollLeft);
    }
  }
}
