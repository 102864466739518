import {EventEmitter, Injectable} from '@angular/core';
import {ConstantService} from "../constants/constant.service";

@Injectable({
  providedIn: 'root'
})
export class DisclaimerService {
  isModalOpen: boolean;
  public hasAcceptedTerms: boolean;
  public userAcceptedTermsEvent = new EventEmitter();
  public hasAcceptedCookies: boolean;
  constructor(private constants: ConstantService) {
    if (this.constants.configuration.saveTermsAndConditionSettings) {
      this.hasAcceptedTerms = localStorage.getItem('hasAcceptedTerms') !== null;
    } else {
      this.hasAcceptedTerms = false;
    }
    this.hasAcceptedCookies = localStorage.getItem('hasAcceptedCookies') == 'true';
    console.log('hasAcceptedTerms:' + this.hasAcceptedTerms);
    this.isModalOpen = !this.hasAcceptedTerms;

  }
  acceptTerms(){
    this.isModalOpen = false
    localStorage.setItem('hasAcceptedTerms', 'true');
    this.hasAcceptedTerms = true;
    this.userAcceptedTermsEvent.emit();
  }
  acceptCookies() {
    localStorage.setItem('hasAcceptedCookies', 'true');
    this.hasAcceptedCookies = true;
  }
  denyCookies() {
    localStorage.setItem('hasAcceptedCookies', 'false');
    this.hasAcceptedCookies = false;
  }
}
