<div class="d-flex align-items-center" style="gap: 8px;"

>
  <div class="indicator" *ngFor="let icon of contentCategory"   style="border-radius: 512px;
overflow: hidden"
  >
    <div *ngIf="icon === 'BEV'" class="d-flex align-items-center justify-content-center bev-background icon-base"

         #flashIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
        <path d="M5.5 3.33V8.1H8.434L4.6 14.67V9.9H1.666L5.5 3.33ZM6.4 0L0.0999985 10.8H3.7V18L10 7.2H6.4V0Z"
              fill="black"/>
      </svg>
    </div>
    <div *ngIf="icon === 'HYBRID'" class="d-flex align-items-center justify-content-center hybrid-background icon-base"
         style="gap: 4px;"
         #hybridIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
        <path d="M5.5 3.33V8.1H8.434L4.6 14.67V9.9H1.666L5.5 3.33ZM6.4 0L0.0999985 10.8H3.7V18L10 7.2H6.4V0Z"
              fill="black"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
        <path
          d="M10.17 0H9L11 2V5L13 7.05V15.75C13 16.9 12.53 17 12 17C11.47 17 11 16.9 11 15.75V10.12C11.0507 9.73464 10.9821 9.34299 10.8033 8.99787C10.6244 8.65276 10.3441 8.37079 10 8.19C9.69572 8.07561 9.37487 8.01145 9.05 8H8V2.11C8 1 7.2 0 4 0C0.8 0 0 1 0 2.11V18H8V9H9.07C9.73 9 9.97 9.27 10 10V15.75C10 17 10.5 18 12 18C13.5 18 14 17 14 15.75V3.5L10.17 0ZM7 17H1V2.11C1 1.81 1 1 4 1C7 1 7 1.81 7 2.11V17Z"
          fill="black"/>
        <path d="M2 5H6V6H2V5Z" fill="black"/>
      </svg>
    </div>
    <div *ngIf="icon === 'ICE'"
         class="d-flex align-items-center justify-content-center gas-station-background icon-base"
         #gasStationIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
        <path
          d="M10.17 0H9L11 2V5L13 7.05V15.75C13 16.9 12.53 17 12 17C11.47 17 11 16.9 11 15.75V10.12C11.0507 9.73464 10.9821 9.34299 10.8033 8.99787C10.6244 8.65276 10.3441 8.37079 10 8.19C9.69572 8.07561 9.37487 8.01145 9.05 8H8V2.11C8 1 7.2 0 4 0C0.8 0 0 1 0 2.11V18H8V9H9.07C9.73 9 9.97 9.27 10 10V15.75C10 17 10.5 18 12 18C13.5 18 14 17 14 15.75V3.5L10.17 0ZM7 17H1V2.11C1 1.81 1 1 4 1C7 1 7 1.81 7 2.11V17Z"
          fill="black"/>
        <path d="M2 5H6V6H2V5Z" fill="black"/>
      </svg>
    </div>
  </div>
</div>
