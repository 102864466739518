import {inject, Injectable} from '@angular/core';
import {DialogConfig} from "../interfaces/dialog.config";
import {ContentBlock} from "../interfaces/contentBlock";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {ConstantService} from "../constants/constant.service";
import {WebsocketManagerService} from "./websocket-manager.service";

@Injectable({
  providedIn: 'root'
})
export class DbService {
  currentConfig: DialogConfig;
  contentBlocks: ContentBlock[];

  constructor(private router: Router,
              private http: HttpClient,
              private constants:ConstantService,
              private websocketManagerService: WebsocketManagerService) { }

  connect(contactId: string){
    this.http.post(this.constants.endpoint + 'api/v1/token/', {contactId}).subscribe((res: any) => {
      this.websocketManagerService.registerWebsocketConnection(res.access_token, contactId);
      this.websocketManagerService.sendWebsocketMessage('###init###', null);
    });
    this.websocketManagerService.onContentBlocksUpdated.subscribe(value => {
      this.contentBlocks = value;
      console.log('Websocket updated ContentBlocks');
    })
    this.websocketManagerService.onConfigUpdated.subscribe(value => {
      this.currentConfig = value;
      console.log('Websocket updated dialogConfig');
    });
    this.http.get(this.constants.endpoint + 'alpha/api/session-exists/'+contactId+'/').subscribe((res: any) => {
      if (!res.exists) {
        console.log('error db entry does not exist');
        this.router.navigate(['/']);
      }
    });
  }
}
