<div
  *ngIf="!constants.configuration.useCustomLandingPage"
  [style.background]="'url('+ constants.configuration.welcomeImageSrc +')'"
  class="landing-page-base">
  <div class="d-flex flex-column justify-content-end align-items-start" style="  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(10px);
  padding-left: 64px;
     padding-bottom: 184px;
padding-right: 64px;">

    <div class="d-flex flex-column">

      <p-text theme="dark" size="inherit" style="font-size: 60px;">
        <span style="font-size: 80px !important;">Your personal Car</span>
      </p-text>
      <p-text theme="dark" size="inherit" style="font-size: 60px;">
        Consultant is ready for you
      </p-text>
      <div style="margin-top: 32px;" class="d-flex justify-content-start">
        <p-button theme="dark" [variant]="'secondary'" (click)="navigateToStart('guided')">Start</p-button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="constants.configuration.useCustomLandingPage">
  <div class="landing-page-base" style="">
    <div
      class="background-image custom-background-filter d-flex justify-content-center align-items-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="543" height="725" viewBox="0 0 543 725" fill="none">
        <path
          d="M543 362.982C543 562.909 421.445 724.982 271.5 724.982C121.555 724.982 0 562.909 0 362.982C0 163.055 121.555 0.982422 271.5 0.982422C421.445 0.982422 543 163.055 543 362.982Z"
          fill="url(#paint0_linear_3962_1406)"/>
        <defs>
          <linearGradient id="paint0_linear_3962_1406" x1="271.5" y1="0.982636" x2="271.5" y2="724.983"
                          gradientUnits="userSpaceOnUse">
            <stop stop-color="#110d5a"/>
            <stop offset="1" stop-color="#070527"/>
          </linearGradient>
        </defs>
      </svg>
    </div>


    <div class="d-flex justify-content-center align-items-center" style=" padding-top: 5rem;
  backdrop-filter: blur(10px);-webkit-backdrop-filter: blur(10px);">
      <div class="d-flex flex-column"
           [style.padding-right.px]="mobileSizeService.isMobileSize ? 16: 0"
           [style.padding-left.px]="mobileSizeService.isMobileSize ? 16: 0">
        <div class="custom-headline">Meet {{ constants.configuration.avatarName }}
        </div>
        <div class="custom-subtitle" [class.mobile-custom-subtitle]="mobileSizeService.isMobileSize">
          EVE is your AI-powered car buying assistant. Simplify your search for the perfect car with tailored guidance
          at
          every step. Sign up now to experience the future of car buying!
        </div>
        <div class="custom-eve-button"
             (click)="navigateToStart('guided')"
        >
          Talk to {{ constants.configuration.avatarName }}
        </div>
      </div>
      <div style="margin-left: 2rem;" *ngIf="!mobileSizeService.isMobileSize">
        <img src="https://uploads-ssl.webflow.com/662f73fe5eff0d66e8add91e/667007a0999fd4fa869b2230_card.png"
             (click)="navigateToStart('guided')">
      </div>
    </div>
  </div>
</div>
<app-cookie-banner></app-cookie-banner>
