import {System} from "../app/interfaces/system";
import {defaultAppConfiguration} from "./app-configurations/selene-app-configuration";
import {seleneCloudAvatarApiKey} from "./avatar-token/avatar-token";
import {eveAppConfiguration} from "./app-configurations/eve-app-configuration";

export const environment: System = {
  appConfiguration: eveAppConfiguration,
  endpoint: 'https://sg-dev.tripleai.co/',
  smApiKey: seleneCloudAvatarApiKey,
  wsEndpoint: 'wss://sg-dev.tripleai.co/',
  googleTagManagerId: "GTM-MGN84BXK"
}
