import {Injectable} from '@angular/core';
import {ConstantService} from "../constants/constant.service";
import {Observable, Subject} from "rxjs";
import {ContentBlock} from "../interfaces/contentBlock";
import {DialogConfig} from "../interfaces/dialog.config";

@Injectable({
  providedIn: 'root'
})
export class WebsocketManagerService {
  private ws: WebSocket;
  public contactId: string;
  private systemTextResponseTrigger = new Subject<{ message: string, ogMessage: string }>();
  public onSystemTextResponse: Observable<{ message: string, ogMessage: string }>;
  private contentBlocksUpdatedTrigger = new Subject<ContentBlock[]>();
  public onContentBlocksUpdated = new Observable<ContentBlock[]>();
  private configUpdatedTrigger = new Subject<DialogConfig>();
  public onConfigUpdated = new Observable<DialogConfig>();
  private onNewConversationTurnTrigger = new Subject();
  public onNewConversationTurn = new Observable();


  constructor(private constant: ConstantService) {
    this.onSystemTextResponse = this.systemTextResponseTrigger.asObservable();
    this.onContentBlocksUpdated = this.contentBlocksUpdatedTrigger.asObservable();
    this.onConfigUpdated = this.configUpdatedTrigger.asObservable();
    this.onNewConversationTurn = this.onNewConversationTurnTrigger.asObservable();
  }

  public registerWebsocketConnection(token: string, contactId: string) {
    this.contactId = contactId;
    this.ws = new WebSocket(this.constant.wsEndpoint + 'ws/connect/?access_token=' + token);
    this.ws.onmessage = this.handleWebsocketMessage.bind(this);
    this.ws.onclose = function () {
      console.error('Chat socket closed unexpectedly');
    };
  }

  handleWebsocketMessage(e: any) {
    let data = JSON.parse(e.data);
    if (data['message-type'] == 'system-text-response' || !data['message-type']) {
      let message = data['message'];
      let ogMessage = data['ogMessage'];
      this.systemTextResponseTrigger.next({message, ogMessage});
      return;
    }
    if (data['message-type'] == 'state-change-content-blocks') {
      this.contentBlocksUpdatedTrigger.next(data['contentBlocks']);
    }
    if (data['message-type'] == 'state-change-config') {
      this.configUpdatedTrigger.next(data['config']);
    }
    if (data['message-type'] == 'new-conversation-turn') {
      this.onNewConversationTurnTrigger.next({});
    }
  };

  public sendWebsocketMessage(message: string, guidedStep: string | null) {
    const websocketTypePrefix = localStorage.getItem('websocket-prefix');

    const type = websocketTypePrefix ? websocketTypePrefix + '-chat-message' : 'chat-message';
    const data = JSON.stringify({
      'type': type,
      'message': message,
      'contactId': this.contactId,
      'guidedStep': guidedStep
    });
    if (this.ws.OPEN == this.ws.readyState) {
      this.ws.send(data);
    } else {
      this.ws.addEventListener('open', () => {
        this.ws.send(data)
      });
    }
  }
}
