<nav class="vw-100 position-fixed d-flex justify-content-between align-items-center"
     *ngIf="!mobileSizeService.isMobileSize"
     style="top: 0; left: 0; padding: 16px 64px;">
  <div style="width: 315px;min-height: 54px;"><!-- logo center --></div>
  <img style="cursor: pointer;" [src]="constantService.configuration.navLogSrc" (click)="logoClicked()" alt="Logo"/>
  <div class="d-flex" style="gap: 16px;">
    <button [ngClass]="constantService.configuration.navButtonClass" class="white-text"
            (click)="contactDetailDialogService.openFeedbackFlow()">
      Share your Feedback
    </button>
    <button [ngClass]="constantService.configuration.navButtonClass" class="white-text" (click)="contactUsClicked()">
      {{ constantService.configuration.navContactButtonLabel }}
    </button>
  </div>

</nav>
<nav class="vw-100 position-fixed d-flex justify-content-between align-items-center"
     style="top: 0; left: 0; padding: 16px 32px; height: 80px;" *ngIf="mobileSizeService.isMobileSize">
  <img style="cursor: pointer;" [src]="constantService.configuration.navLogSrc" (click)="logoClicked()" alt="Logo"/>
  <div class="nav-item dropdown">
    <a class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
      <div style="height: 20px; width: 20px; fill: white; color: white;">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
          <path
            d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z"/>
        </svg>
      </div>
    </a>
    <ul class="dropdown-menu" style="z-index: 100;">
      <li (click)="contactDetailDialogService.openFeedbackFlow()"><a class="dropdown-item">Share your Feedback</a></li>
      <li (click)="contactUsClicked()"> <a class="dropdown-item"> {{ constantService.configuration.navContactButtonLabel }}</a></li>
      <li>
        <hr class="dropdown-divider">
      </li>
      <li><a
        class="dropdown-item"
             [href]="constantService.configuration.termsAndConditionsUrl"
             target="_blank">
        Terms and Conditions
      </a></li>
      <li>
        <a class="dropdown-item" href="https://www.tripleai.co" target="_blank">Powered by Triple AI</a>
      </li>
    </ul>
  </div>

</nav>
