<p-modal *ngIf="!constantService.configuration.useCustomDialogs" [open]="contactDetailDialogService.isModalOpen"
         (dismiss)="contactDetailDialogService.onDismiss()" [aria]="{ 'aria-label': 'Some Heading' }">
  <div *ngIf="contactDetailDialogService.isContactDetailWorkflow()">
    <div class="mb-4">
      <p-heading>Contact Information</p-heading>
    </div>
    <p-divider></p-divider>
    <div class="d-flex align-items-end justify-content-center mt-4 mb-4">
      <form [formGroup]="contactForm">
        <p-text-field-wrapper [label]="'Name'" [hideLabel]="false">
          <input [type]="'text'" [name]="'name'" formControlName="name"/>
        </p-text-field-wrapper>
        <p-text-field-wrapper [label]="'Email or Phone'" [hideLabel]="false">
          <input [type]="'email'" [name]="'email'" formControlName="email"/>
        </p-text-field-wrapper>
        <p-text-field-wrapper [label]="'Location'" [hideLabel]="false" hidden="">
          <input [type]="'text'" [name]="'location'" formControlName="location"/>
        </p-text-field-wrapper>
        <div class="mt-3">
          <p-checkbox-wrapper>
            <span slot="label" id="some-label-id">I have read and agree to the <a
              [href]="constantService.configuration.privacyPolicyUrl" target="_blank">Privacy Policy</a></span>
            <input type="checkbox" [name]="'agb'" formControlName="agb"/>
            <span slot="message" id="some-message-id">This is required. Consider checking the Box.</span>
          </p-checkbox-wrapper>
        </div>
      </form>
    </div>
    <div class="d-flex justify-content-center">
      <p-button [icon]="'arrow-right'" [disabled]="contactForm.invalid" (click)="submit()">Submit</p-button>
    </div>
  </div>
  <div *ngIf="contactDetailDialogService.isNpsWorkflow()">
    <app-nps-flow [email]="contactForm.controls.email.value"></app-nps-flow>
  </div>
</p-modal>
<div class="custom-modal-backdrop"
     (click)="contactDetailDialogService.onDismiss()"
     *ngIf="constantService.configuration.useCustomDialogs && contactDetailDialogService.isModalOpen">
  <div class="custom-contact-info-card" (click)="preventClickBubbleUp($event)">
    <div *ngIf="contactDetailDialogService.isContactDetailWorkflow()">
      <form [formGroup]="contactForm">
        <div class="contact-info-header d-flex justify-content-between">
          <div>
            Contact Information
          </div>
          <div class="close-button" (click)="contactDetailDialogService.onDismiss()">
            x
          </div>
        </div>
        <div class="modal-container">
          <input class="custom-modal-input" placeholder="Name" [type]="'text'" [name]="'name'" formControlName="name"/>

          <input class="custom-modal-input" placeholder="Email or Phone" [type]="'email'" [name]="'email'"
                 formControlName="email"/>

          <input class="custom-modal-input" [type]="'text'" [name]="'location'" hidden="" formControlName="location"/>
        </div>
        <div class="custom-checkbox" (click)="toggleCheckbox()">
          <input type="checkbox" [name]="'agb'" formControlName="agb"><label>I have read and agree to <a
          (click)="preventClickBubbleUp($event)" [href]="constantService.configuration.privacyPolicyUrl"
          target="_blank">Privacy
          Policy</a></label>
        </div>
      </form>
      <div class="modal-action">
        <button [disabled]="contactForm.invalid" (click)="submit()" class="modal-button">Confirm</button>
      </div>
    </div>
    <div *ngIf="contactDetailDialogService.isNpsWorkflow()">
      <app-nps-flow [email]="contactForm.controls.email.value"></app-nps-flow>
    </div>
  </div>
</div>
