<div *ngIf="currentConfig && !showIsProcessing" class="w-100 d-flex flex-column align-items-end">
  <div *ngIf="!constantService.configuration.useCustomDialogs" class="w-100">
    <p-button [theme]="'dark'" *ngFor="let btn of currentConfig.buttons;  let i = index"
              class="w-100 m-1 pt-1 ps-1 pe-1 pb-1"
              (click)="sendButtonMessage(btn)">
      {{ btn.text }}
    </p-button>
  </div>
  <div *ngIf="constantService.configuration.useCustomDialogs" class="w-100 d-flex flex-column" style="gap:8px;" >
    <div *ngFor="let btn of currentConfig.buttons;  let i = index"
         class="custom-button w-100"
         (click)="sendButtonMessage(btn)">
      {{ btn.text }}
    </div>
  </div>
</div>
