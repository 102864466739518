import {Injectable} from '@angular/core';
import {AppConfiguration} from "../interfaces/app-configuration";
import {environment} from "../../environments/environment";

/*
const cloudDevEnv: Environment = {
  endpoint: 'https://triplemvp.tripleai-dev.com/',
  smApiKey: seleneCloudAvatarApiKey,
  wsEndpoint: 'wss://triplemvp.tripleai-dev.com:443/'
}

const prodEveEnv: Environment = {
  endpoint: 'https://eve-bknd-sg.tripleai-dev.com/',
  smApiKey: eveCloudAvatarApiKey,
  wsEndpoint: 'wss://eve-bknd-sg.tripleai-dev.com:443/'
}

const singaporeSystem: System = {
  appConfiguration: defaultAppConfiguration,
  environment: prodEnv
}
const singaporeEveSystem: System = {
  appConfiguration: eveAppConfiguration,
  environment: prodEveEnv
}
const betaSystem: System = {
  appConfiguration: defaultAppConfiguration,
  environment: cloudDevEnv
}

const devSystem: System = {
  appConfiguration: eveAppConfiguration,
  environment: localDevEnv
}

 */

@Injectable({
  providedIn: 'root'
})
export class ConstantService {
  public configuration: AppConfiguration = environment.appConfiguration;
  public readonly endpoint = environment.endpoint;
  public readonly wsEndpoint = environment.wsEndpoint;
  public readonly smApiKey = environment.smApiKey;
}
