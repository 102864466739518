import {Component, HostListener, OnInit} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'porsche-eve-demo';

  constructor() {
    this.checkSizeWarning();
  }

  ngOnInit(): void {
    }
  showSizeWarning = false;

  @HostListener('window:resize', ['$event'])
  checkSizeWarning() {
    this.showSizeWarning = window.innerHeight >= window.innerWidth;
  }
}
