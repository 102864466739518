import {Component, Input} from '@angular/core';
import {ContentBlock} from "../../interfaces/contentBlock";

@Component({
  selector: 'app-presentation-area',
  templateUrl: './presentation-area.component.html',
  styleUrls: ['./presentation-area.component.css']
})
export class PresentationAreaComponent {
  @Input() contentBlocks: ContentBlock[];
}
