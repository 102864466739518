import {Component, Input} from '@angular/core';
import {ContactDetailDialogService} from "../../../services/contact-detail-dialog.service";
import {ActivatedRoute} from "@angular/router";
import {ConstantService} from "../../../constants/constant.service";

@Component({
  selector: 'app-nps-flow',
  templateUrl: './nps-flow.component.html',
  styleUrls: ['./nps-flow.component.css']
})
export class NpsFlowComponent {
  @Input()
  email: string | null;
  public npsButtons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  public selectedNpsScore: number;
  public isSubmitted = false;
  public comment: string;
  public avatarName: string;
  public userConsentToBeContacted = false;
  private npsResultIsSubmitting = false;

  constructor(public contactDetailDialogService: ContactDetailDialogService,
              private route: ActivatedRoute,
              public constantsService: ConstantService) {
    this.avatarName = this.constantsService.configuration.avatarName;
  }

  onSubmit() {
    if (!this.npsResultIsSubmitting) {
      // @ts-ignore
      let contactId = this.route.snapshot.paramMap.params.id
      this.npsResultIsSubmitting = true;
        this.contactDetailDialogService.sendNps(
          contactId,
          this.email || '',
          this.selectedNpsScore,
          this.comment,
          this.userConsentToBeContacted)
          .subscribe(() => {
            this.npsResultIsSubmitting = false;
            this.isSubmitted = true;
          });
    }
  }
}
