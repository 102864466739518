import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MainViewComponent} from "./components/main-view/main-view.component";
import {StartComponent} from "./components/start/start.component";
import {LandingPageComponent} from "./components/landing-page/landing-page.component";
import {ImmediateSessionStartComponent} from "./components/imidiate-session-start/immediate-session-start.component";

const routes: Routes = [
  { path: 's/:id', component: MainViewComponent},
  { path: '', component: StartComponent  },
  { path: 'landing', component: ImmediateSessionStartComponent },
  { path: '**', redirectTo: 'landing' },
]; // sets up routes constant where you define your routes

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
