import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {AvatarService} from "../../services/avatar.service";
import {ConstantService} from "../../constants/constant.service";

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.css']
})
export class TextInputComponent {
  private SpeechRecognition: any;
  speechRecognitionInProgress = false;
  @Input() public userMessage: string;
  @Output() public userMessageChange = new EventEmitter<string>();
  @Output() public sendUserMessageClicked = new EventEmitter<string>();
  @Output() public onUserTyping = new EventEmitter();

  userMessageFormControl = new FormControl();

  constructor(public constantService: ConstantService) {
    this.userMessageFormControl.valueChanges.subscribe(res => {
      this.onTyping();
    });
    try {


      // @ts-ignore
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      // @ts-ignore
      this.SpeechRecognition = new SpeechRecognition();

      // Set the properties for the speech recognition
      this.SpeechRecognition.continuous = true; // Set to false to stop recording after the user pauses speaking.
      this.SpeechRecognition.lang = 'en-US'; // Set the language of the recognition.
      this.SpeechRecognition.interimResults = false; // Set to true for results returned while user speaks. Doesn't work reliably yet.

      // Define what happens when results are returned from the recognition service
      this.SpeechRecognition.onresult = this.onSpeechRecognitionResult.bind(this);

      // Define what happens when speech recognition ends
      this.SpeechRecognition.onend = function () {
        // Placeholder for functions at end of speech recognition here if needed.
      };

      // Error handling
      this.SpeechRecognition.onerror = function (event: any) {
        console.error(event.error); // Log the error
      };
    } catch (error) {

    }
  }

  sendUserMessage(userMessage: string) {
    this.userMessageFormControl.reset();
    this.sendUserMessageClicked.emit(userMessage);
  }

  onTyping() {
    this.onUserTyping.emit();
  }

  buttonClicked() {
    if (this.userMessageFormControl.value) {
      this.sendUserMessage(this.userMessageFormControl.value);
    } else {
      if (this.speechRecognitionInProgress) {
        this.stopVoiceRecognition();
      } else {
        this.startVoiceRecognition();
      }
    }
  }

  onSpeechRecognitionResult(event: any) {
    let speechResult = event.results[0][0].transcript; // Get the text.
    this.userMessageFormControl.setValue(speechResult);
  }

  startVoiceRecognition() {
    this.SpeechRecognition.start();
    this.speechRecognitionInProgress = true;
  }

  stopVoiceRecognition() {
    this.SpeechRecognition.stop();
    this.speechRecognitionInProgress = false;
  }
}
