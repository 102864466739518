<div class="d-flex flex-column justify-content-center align-items-center"
     [style.background]="'url('+ constants.configuration.welcomeImageSrc +')'"
     style="
     height: 100vh;
     width: 100%;
     background-size: cover;
     background-position: top;
     position: fixed;
">
  <div class="blur-layer d-flex flex-column justify-content-center align-items-center">
    <p-inline-notification
      *ngIf="hasError"
      [heading]="'Not available in your Region'"
      [description]="errorText"
      [state]="'error'"
      (dismiss)="onDismiss()"
    >
    </p-inline-notification>
    <p-inline-notification
      *ngIf="showDisclaimer && !hasError"
      [heading]="'Beta Version'"
      [description]="disclaimerText"
      [state]="'warning'"
      (dismiss)="onDismiss()"
    >
    </p-inline-notification>

    <div class="mt-2" *ngIf="false">
      <p-inline-notification
        *ngIf="showDisclaimer"
        [heading]="'Maintenance'"
        [description]="'We are performing some essential maintenance to keep everything running smoothly. We\'re working hard to get everything back up and running as quickly as possible.\n'+
'\n'+
'Thank you for your patience and understanding!'"
        [state]="'error'"
        [dismissButton]="false"
      >
      </p-inline-notification>
    </div>
    <!--
    <div class="disclaimer">
      <p>This is a beta version of Porsche's Virtual Car Sales Assistant which is currently in a testing phase. Your
        feedback is crucial to help us innovate and improve.</p>
    </div>
    -->
    <div class="chat-container">
      <!-- Messages will be dynamically inserted here -->
      <div *ngFor="let msg of displayedMessages" class="message" [ngClass]="msg.type">
        {{ msg.text }}<span *ngIf="isTyping && msg === displayedMessages[displayedMessages.length - 1]"
                            class="cursor">|</span>
      </div>
      <div *ngIf="!isTyping" class="button-container" style="display: flex;">
        <a href="https://www.porsche.com/singapore/en/">
          <p-button variant="secondary" theme="dark" hide-label="{ base: true, s: false }" icon="arrow-right">Chat with
            the Porsche Support Team
          </p-button>
        </a>
        <p-button variant="primary" theme="dark" hide-label="{ base: true, s: false }" (click)="talkToSelene()"
                  icon="arrow-right">Talk to SELENE
        </p-button>
      </div>
    </div>
  </div>
</div>
