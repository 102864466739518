import {Component, OnInit} from '@angular/core';
import {ConstantService} from "../../constants/constant.service";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {GoogleTagManagerService} from "angular-google-tag-manager";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  public hasError = false;
  private messages: { text: string, type: string }[] = [
    {text: "Where am I?", type: "user"},
    {
      text: "You are about to exit the known Porsche space, entering the exciting world of Artificial Intelligence.",
      type: "ai"
    },
    {text: "Why am I here?", type: "user"},
    {
      text: "Porsche is harnessing the latest AI technology to connect you with the sports car of your dreams.",
      type: "ai"
    },
    {text: "How is Porsche doing this?", type: "user"},
    {
      text: "We join forces with innovative partners like Triple AI, pushing the boundaries of what is possible together.",
      type: "ai"
    },
    {text: "What can I experience here and now?", type: "user"},
    {
      text: "Porsche has developed an AI-powered assistant to guide you with interactive and personalised assistance, right at your fingertips.",
      type: "ai"
    }
  ];
  displayedMessages: { text: string, type: string }[] = [];
  isTyping: boolean = false;
  showDisclaimer: boolean = true;
  disclaimerText = "This is a beta version of Porsche's Virtual Car Sales Assistant which is currently in a testing phase. Your feedback is crucial to help us innovate and improve. We currently do NOT support mobile devices yet.";
  errorText = "This service is not yet available in your region. Please revisit us soon.";

  constructor(public constants: ConstantService, private http: HttpClient, private router: Router, private gtm: GoogleTagManagerService) {
    this.http.get(environment.endpoint + 'ping/', {observe: 'response'})
      .subscribe(response => {
          console.log('heartbeat ok');

        }, error => {
          this.hasError = true;
        }
      )
    this.talkToSelene();
  }

  ngOnInit(): void {
    this.displayMessages();
    const gtmTag = {
      event: 'page-enter',
      pageName: 'landing'
    }
    this.gtm.pushTag(gtmTag);
  }

  async displayMessages() {
    for (const msg of this.messages) {
      await this.typeMessage(msg);
    }
    this.isTyping = false;
  }

  typeMessage(message: { text: string, type: string }) {
    return new Promise<void>(resolve => {
      this.isTyping = true;
      let i = 0;
      const typingInterval = setInterval(() => {
        if (i < message.text.length) {
          const currentMsg = message.text.slice(0, i + 1);
          if (this.displayedMessages.length === this.messages.indexOf(message)) {
            this.displayedMessages.push({text: currentMsg, type: message.type});
          } else {
            this.displayedMessages[this.messages.indexOf(message)].text = currentMsg;
          }
          i++;
        } else {
          clearInterval(typingInterval);
          setTimeout(() => {
            this.isTyping = false;
            resolve();
          }, 1500);
        }
      }, 15);
    });
  }

  goBack() {
    window.history.back();
  }

  talkToSelene() {
    const url = this.constants.endpoint + 'start/';
    this.http.post(url, {type: 'guided'}).subscribe((res: any) => {
      this.router.navigate(['s', res.session]);
    });
  }

  onDismiss() {
    this.showDisclaimer = false;
  }
}
