<p-modal *ngIf="!constantService.configuration.useCustomDialogs" [open]="disclaimerService.isModalOpen"
         [disableBackdropClick]="true" [dismissButton]="false">
  <div style="max-width: 100%; width: 50vw;">
    <p-text>
      By proceeding to chat with Selene, you acknowledge and agree to the following:
    </p-text>
    <p-text-list type="numbered">
      <p-text-list-item>
        <b>Use at Your Own Discretion.</b> The information and guidance provided by Selene should be used solely as a
        reference. We strongly advise you against relying on Selene for any decision-making, including but not limited
        to making financial decisions. You agree that any decisions or actions you take, or choose not to take, based on
        the information provided by Selene, are completely at your own risk.
      </p-text-list-item>
      <p-text-list-item>
        <b>Restriction of Use.</b> You may not use Selene for any illegal, immoral, harmful or abusive activities.
      </p-text-list-item>
      <p-text-list-item>
        <b>Sharing of Personal Data.</b> You should not provide any personal data to Selene. Any personal data you
        disclose to
        Selene shall be deemed voluntary, and you agree to have provided your consent for the use and processing of your
        personal data.
      </p-text-list-item>
      <p-text-list-item>
        <b>Terms of Use.</b> You agree to the terms of use stated <a
        [href]="constantService.configuration.termsAndConditionsUrl" target="_blank">here</a>.
      </p-text-list-item>
      <p-text-list-item>
        <b>Disclaimer.</b> As Selene adopts AI technologies, there is a possibility that Selene may generate Output that are offensive, inappropriate and incorrect that does not reflect the official opinion or position of Porsche or Triple AI.
      </p-text-list-item>
    </p-text-list>
  </div>
  <div class="d-flex justify-content-center" style="margin-top: 32px;">
    <p-button (click)="onAccept()">
      Accept
    </p-button>
  </div>
</p-modal>
<div class="custom-modal-backdrop custom-modal-dark-backdrop"
     *ngIf="constantService.configuration.useCustomDialogs && disclaimerService.isModalOpen">
  <div class="modal-card" style="max-width: 100%; width: 50vw; max-height: 80vh; overflow: scroll;" [style.width.vw]="mobileSizeService.isMobileSize ? 80 : 50">
    <div class="disclaimer-header">Terms of Use
    </div>
    <div class="disclaimer-text">
      <ul>
        <div style="margin-bottom: 32px;">By proceeding to chat with EVE, you acknowledge and agree to the following:</div>

        <li>
          Use at Your Own Discretion. The information and guidance provided by EVE should be used solely as a
          reference. We strongly advise you against relying on EVE for any decision-making, including but not limited to
          making financial decisions. You agree that any decisions or actions you take, or choose not to take, based on
          the information provided by EVE, are completely at your own risk.
        </li>
        <li>
          Restriction of Use. You may not use EVE for any illegal, immoral, harmful or abusive activities.
        </li>
        <li>
          Sharing of Personal Data. You should not provide any personal data to EVE. Any personal data you disclose to
          EVE shall be deemed voluntary, and you agree to have provided your consent for the use and processing of your
          personal data.
        </li>
        <li>
          Terms of Use. You agree to the terms of use stated <a
           style="color: #FFFFFF;"
          [href]="constantService.configuration.termsAndConditionsUrl" target="_blank">here</a>
        </li>
      </ul>
    </div>
    <div class="modal-action">
      <button (click)="onAccept()" class="modal-button">Accept ToS</button>
    </div>
  </div>
</div>
