import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {HttpClientJsonpModule, HttpClientModule} from "@angular/common/http";
import {NgOptimizedImage} from "@angular/common";
import {AppRoutingModule} from "./app-routing.module";
import {MainViewComponent} from './components/main-view/main-view.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {StartComponent} from './components/start/start.component';
import {TextInputComponent} from './components/text-input/text-input.component';
import {ConversationHistoryComponent} from './components/conversation-history/conversation-history.component';
import {GuidedViewComponent} from './components/guided-view/guided-view.component';
import {PresentationAreaComponent} from './components/presentation-area/presentation-area.component';
import {
  AnswerButtonRecommendationComponent
} from './components/answer-button-recommendation/answer-button-recommendation.component';
import {PorscheDesignSystemModule} from "@porsche-design-system/components-angular";
import {
  SingleTemplateComponent
} from './components/presentation-area/templates/single-template/single-template.component';
import {DuoTemplateComponent} from './components/presentation-area/templates/duo-template/duo-template.component';
import {MultiTemplateComponent} from './components/presentation-area/templates/multi-template/multi-template.component';
import {ContentBlockComponent} from './components/presentation-area/templates/content-block/content-block.component';
import {GoogleMapsModule} from "@angular/google-maps";
import { ChargingStationFinderComponent } from './components/charging-station-finder/charging-station-finder.component';
import { AvatarPanelComponent } from './components/avatar-panel/avatar-panel.component';
import {ContactDetailsDialogComponent} from "./components/contact-details-dialog/contact-details-dialog.component";
import { FooterComponent } from './components/footer/footer.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { ContactDetailButtonComponent } from './components/contact-detail-button/contact-detail-button.component';
import { HeaderComponent } from './components/header/header.component';
import { EngineTypeIndicatorComponent } from './components/presentation-area/templates/content-block/engine-type-indicator/engine-type-indicator.component';
import { NpsFlowComponent } from './components/contact-details-dialog/nps-flow/nps-flow.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { CookieBannerComponent } from './components/cookie-banner/cookie-banner.component';
import {environment} from "../environments/environment";
import { MobileSingleTemplateComponent } from './components/presentation-area/mobile/mobile-single-template/mobile-single-template.component';
import { MobileDuoTemplateComponent } from './components/presentation-area/mobile/mobile-duo-template/mobile-duo-template.component';
import { MobileContentBlockComponent } from './components/presentation-area/mobile/mobile-content-block/mobile-content-block.component';
import { ImmediateSessionStartComponent } from './components/imidiate-session-start/immediate-session-start.component';

@NgModule({
    declarations: [
        AppComponent,
        MainViewComponent,
        StartComponent,
        TextInputComponent,
        ConversationHistoryComponent,
        GuidedViewComponent,
        PresentationAreaComponent,
        AnswerButtonRecommendationComponent,
        SingleTemplateComponent,
        DuoTemplateComponent,
        MultiTemplateComponent,
        MultiTemplateComponent,
        ContentBlockComponent,
        ChargingStationFinderComponent,
        AvatarPanelComponent,
        ContactDetailsDialogComponent,
        FooterComponent,
        DisclaimerComponent,
        ContactDetailButtonComponent,
        HeaderComponent,
        EngineTypeIndicatorComponent,
        NpsFlowComponent,
        LandingPageComponent,
        CookieBannerComponent,
        MobileSingleTemplateComponent,
        MobileDuoTemplateComponent,
        MobileContentBlockComponent,
        ImmediateSessionStartComponent
    ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    PorscheDesignSystemModule,
    GoogleMapsModule,
    HttpClientJsonpModule,
    FormsModule,

  ],
  providers: [{provide: 'googleTagManagerId', useValue: environment.googleTagManagerId}],
  bootstrap: [AppComponent]
})
export class AppModule {
}
