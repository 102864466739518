import {Component, HostListener, Input} from '@angular/core';
import {ConversationMessage} from "../../interfaces/conversation.message";
import {DialogConfig} from "../../interfaces/dialog.config";
import {ContentBlock} from "../../interfaces/contentBlock";
import {ContactDetailDialogService} from "../../services/contact-detail-dialog.service";
import {ConstantService} from "../../constants/constant.service";
import {MobileSizeService} from "../../services/mobile-size.service";

@Component({
  selector: 'app-guided-view',
  templateUrl: './guided-view.component.html',
  styleUrls: ['./guided-view.component.css']
})
export class GuidedViewComponent {
  @Input() currentConfig: DialogConfig;
  @Input() contactId: string;
  @Input() contentBlocks: ContentBlock[];

  constructor(public contactInfoService: ContactDetailDialogService,
              public constantService: ConstantService,
              public mobileSizeService: MobileSizeService) {
  }
  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.ctrlKey && event.shiftKey && event.key === 'D') {
      // Open the debug URL in a new window/tab
      window.open('https://console.firebase.google.com/u/0/project/triple-ai-3c8ac/firestore/data/~2Fsessions~2F' + this.contactId, '_blank', 'width=900,height=1100');
    }
  }

}
