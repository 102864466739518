export class AsyncQueue {
  private queue: { content: string, spoken: boolean }[];
  private resolvePending: any;
  private pendingPromise: Promise<unknown> | null;

  constructor() {
    this.resetQueue();
  }

  public resetQueue() {
    this.queue = [];
    this.pendingPromise = null;
    this.resolvePending = null;
  }

// Method to add an item to the queue
  // Method to add an item to the queue with its spoken status
  enqueue(item: string, spoken = false) {
    const queueItem = {
      content: item,
      spoken: spoken
    };
    if (spoken) {
      console.log('added spoken item to queue', item)
    } else {
      console.log('added item to queue', item)
    }

    this.queue.push(queueItem);
    // If there's a pending operation waiting for items, resolve it
    if (this.resolvePending) {
      this.resolvePending();
      this.resolvePending = null;
      this.pendingPromise = null;
    }
  }

  // Method to get all unspoken items and clear the queue completely
  async dequeueAll(): Promise<any> {
    console.log('dequeue all call');
    if (this.queue.length > 0) {
      const unspokenItems = this.queue.filter(item => !item.spoken).map(item => item.content);
      this.queue = []; // Clear the queue completely
      return unspokenItems;
    } else if (!this.pendingPromise) {
      // Wait for an item to be enqueued
      this.pendingPromise = new Promise(resolve => {
        this.resolvePending = resolve;
      });
      await this.pendingPromise;
      const unspokenItems = this.queue
        .filter(item => !item.spoken)
        .map(item => item.content);
      this.queue = []; // Clear the queue completely
      return unspokenItems;
    }
  }

  public hasItems(excludeSpokenItems = true) {
    let length = excludeSpokenItems
      ? this.queue.filter(q => !q.spoken).length
      : this.queue.length;
    console.log('has items check', length);
    return length > 0;
  }
}



