import {Component, Input} from '@angular/core';
import {ContentBlock} from "../../../../interfaces/contentBlock";
import {HelperService} from "../../../../services/helper.service";

@Component({
  selector: 'app-single-template',
  templateUrl: './single-template.component.html',
  styleUrls: ['./single-template.component.css']
})
export class SingleTemplateComponent {
  @Input() items: ContentBlock[];
}
