import {Component, HostListener, Input} from '@angular/core';
import {ContentBlock} from "../../../../interfaces/contentBlock";

@Component({
  selector: 'app-mobile-content-block',
  templateUrl: './mobile-content-block.component.html',
  styleUrls: ['./mobile-content-block.component.css']
})
export class MobileContentBlockComponent {
  private _item: ContentBlock;
  mainImageLink: string;
  baseDimensionHeight: number;
  private isSingle: boolean;
  baseDimensionWidth: number;
  private _smallLayoutBottomAreaHeight = 109;
  private imageAreaHeight: number;
  private imageAreaWidth: number;
  mainImageWidth: number;
  mainImageHeight: number;
  get item(): ContentBlock {
    return this._item;
  }
  @Input()
  set item(value: ContentBlock) {
    this._item = value;
    if (value){
      this.mainImageLink = this.getBackgroundImageUrl(value);
    }
  }

  getBackgroundImageUrl(item: ContentBlock) {
    if (item.cardBackgroundImageUrl) {
      return item.cardBackgroundImageUrl;
    }
    if (item && item.imageUrls && item.imageUrls?.length > 0) {
      return item.imageUrls[0]
    }
    return ''
  }
  constructor() {
    this.calculateDimensions()
  }

  @HostListener('window:resize', ['$event'])
  private calculateDimensions() {
    this.baseDimensionHeight = window.innerHeight * 0.5;
    this.baseDimensionWidth = window.innerWidth - 24;
    if (this.isSingle) {
      /*
   this.imageAreaHeight = this.baseDimensionHeight - this._wideLayoutBottomAreaLayoutAndHeader;

  this.imageAreaWidth = this.baseDimensionWidth - (this._padding * 2);
  if ((this.imageAreaWidth - 100) / this.imageAreaHeight >= 16 / 9) {
    this.mainImageWidth = this.imageAreaHeight * 16 / 9;
    this.mainImageHeight = this.imageAreaHeight;
    this.imageGalleryWidth = this.imageAreaWidth - this.mainImageWidth - this._gap;
    this.imageGalleryItemHeight = this.imageGalleryWidth * 9 / 16;
  } else {
    this.imageGalleryWidth = 100;
    this.imageGalleryItemHeight = this.imageGalleryWidth * 9 / 16;
    this.mainImageWidth = this.imageAreaWidth - this.imageGalleryWidth - this._gap;
    this.mainImageHeight = this.mainImageWidth * 9 / 16;

   */
    } else {
      this.imageAreaHeight = this.baseDimensionHeight - this._smallLayoutBottomAreaHeight;
      this.imageAreaWidth = this.baseDimensionWidth;
      this.mainImageWidth = this.imageAreaWidth;
      this.mainImageHeight = this.imageAreaWidth * 9 / 16;
    }
  }
}
