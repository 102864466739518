<div *ngIf="item">
  <div *ngIf="item.isImageOnlyContent">
    <div class="model-card model-card-wide m-4"
         style="background-size: cover;background-position: center;"
         [class.white-model-card]="!constantService.configuration.useCustomContentBlock"
         [class.transparent-model-card]="constantService.configuration.useCustomContentBlock"
         *ngIf="isWide"
         [style.width]="'60vw'"
         [style.background-image]="'url(' + mainImageLink + ')'"
    >
    </div>
  </div>
  <div *ngIf="!item.isImageOnlyContent">
    <div class="model-card model-card-wide m-4"
         [class.white-model-card]="!constantService.configuration.useCustomContentBlock"
         [class.transparent-model-card]="constantService.configuration.useCustomContentBlock"
         *ngIf="isWide"
         [style.width]="'60vw'"
    >
      <div class="top-block-wide-design"
           [style.padding-left.px]="getSidePadding()"
           [style.padding-right.px]="getSidePadding()"
      >
        <div class="d-flex align-items-center">
          <div class="content-type-indicator">
            <app-engine-type-indicator [contentCategory]="item.contentCategory"></app-engine-type-indicator>
          </div>
          <div class="content-id"
               [class.black-font]="!constantService.configuration.useCustomContentBlock"
               [class.white-font]="constantService.configuration.useCustomContentBlock"
          >
            {{ item.contentId }}
          </div>
        </div>

        <div class="price">
          {{ item.price }}
        </div>
      </div>
      <div #imageArea class="mid-block-wide-design d-flex align-items-center"
           [style.padding-left.px]="getSidePadding()"
           [style.padding-right.px]="getSidePadding()"
      >
        <div>
          <div
            class="content-image"
            [style.background-image]="'url(' + mainImageLink + ')'"
            [style.width.px]="mainImageWidth"
            [style.height.px]="mainImageHeight"
            [style.max-height.px]="mainImageHeight"
            [class.image-corner-radius-default]="!constantService.configuration.useCustomContentBlock"
            [class.main-image-corner-radius-custom]="constantService.configuration.useCustomContentBlock"
          >
          </div>
        </div>
        <div class="d-flex flex-column overflow-auto" style="gap: 12px;"
             [style.width.px]="imageGalleryWidth"
             [style.min-height.px]="mainImageHeight"
             [style.max-height.px]="mainImageHeight"
        >
          <img class="content-image"
               *ngFor="let image of item.imageUrls" [src]="image"
               [class.gallery-image-default]="!constantService.configuration.useCustomContentBlock"
               [class.selected-image-default]="mainImageLink == image && !constantService.configuration.useCustomContentBlock"
               [class.gallery-image-custom]="constantService.configuration.useCustomContentBlock"
               [class.selected-image-custom]="mainImageLink == image && constantService.configuration.useCustomContentBlock"
               (click)="mainImageLink = image"
               [style.width.px]="imageGalleryWidth - 8 - windowsVerticalScrollbarPrevention"
               [style.height.px]="imageGalleryItemHeight - 8"
          >
        </div>
      </div>

      <div class="d-flex lower-block-wide-design justify-content-between"
           [style.width.px]="mainImageWidth + 24"
           [style.padding-left.px]="getSidePadding()"
           [style.padding-right.px]="getSidePadding()"
      >
        <div *ngFor="let spec of item.table">
          <div class="spec-key">
            {{ spec.key }}
          </div>
          <div class="spec-value"
               [class.black-font]="!constantService.configuration.useCustomContentBlock"
               [class.white-font]="constantService.configuration.useCustomContentBlock"
          >
            {{ spec.value }}
          </div>
        </div>
      </div>
    </div>
    <div class="model-card m-4 model-card-small"
         [class.white-model-card]="!constantService.configuration.useCustomContentBlock"
         [class.transparent-model-card]="constantService.configuration.useCustomContentBlock"
         *ngIf="!isWide"
         style="width: 30vw; max-height: 70vh;">
      <div #imageArea style="width: 30vw; max-height: 70vh;">
        <div
          *ngIf="!helper.isVideo(item)"
          class="" style="background-position: center; background-size: cover; margin-bottom: 16px;"
          [style.background-image]="'url(' + mainImageLink + ')'"
          [style.height.px]="this.mainImageHeight"
          [style.width.px]="this.mainImageWidth"
        >
        </div>
        <div class="d-flex align-items-center overflow-auto" style="
    padding-left: 16px;
    padding-right: 16px;
    gap: 16px;" [style.height.px]="this.imageGalleryHeight"
          #galleryWrapper
          (scroll)="onScroll()"
        >
          <img *ngFor="let image of item.imageUrls" class="content-image"
               [class.gallery-image-default]="!constantService.configuration.useCustomContentBlock"
               [class.selected-image-default]="mainImageLink == image && !constantService.configuration.useCustomContentBlock"
               [class.gallery-image-custom]="constantService.configuration.useCustomContentBlock"
               [class.selected-image-custom]="mainImageLink == image && constantService.configuration.useCustomContentBlock"
               [style.width.px]="imageGalleryItemWidth - 8"
               [style.height.px]="imageGalleryHeight - 8 - windowsVerticalScrollbarPrevention"
               (click)="onImageGallerySelected(image)"
               [src]="image">

        </div>
      </div>
      <div class="text-area">
        <div class="w-100 d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <div>
              <app-engine-type-indicator [contentCategory]="item.contentCategory"></app-engine-type-indicator>
            </div>
            <div class="content-id"
                 [class.black-font]="!constantService.configuration.useCustomContentBlock"
                 [class.white-font]="constantService.configuration.useCustomContentBlock">
              {{ item.contentId }}
            </div>
          </div>
          <div class="price">
            {{ item.price }}
          </div>
        </div>
        <div class="separator"></div>
        <div class="w-100 d-flex justify-content-between align-items-start">
          <div *ngFor="let spec of item.table" class="" [style.max-width.px]="(imageAreaWidth - 48)/3">
            <div class="spec-key" [title]="spec.key">
              {{ spec.key }}
            </div>
            <div class="spec-value"
                 [title]="spec.value"
                 [class.black-font]="!constantService.configuration.useCustomContentBlock"
                 [class.white-font]="constantService.configuration.useCustomContentBlock"
            >
              {{ spec.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
