import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ContactDetailDialogService} from "../../services/contact-detail-dialog.service";
import {ActivatedRoute} from "@angular/router";
import {ConstantService} from "../../constants/constant.service";
import {DisclaimerService} from "../../services/disclaimer.service";
import {GoogleTagManagerService} from "angular-google-tag-manager";

@Component({
  selector: 'app-contact-details-dialog',
  templateUrl: './contact-details-dialog.component.html',
  styleUrls: ['./contact-details-dialog.component.css']
})
export class ContactDetailsDialogComponent {
  private isSubmittingContactInfo = false;
  contactForm = new FormGroup({
    name: new FormControl('', [
      Validators.required
    ]),
    email: new FormControl('', [
      Validators.required
    ]),
    location: new FormControl({value: 'Singapore', disabled: true}, [
      Validators.required
    ]),
    agb: new FormControl(false, [
      Validators.requiredTrue
    ]),
  });

  constructor(public contactDetailDialogService: ContactDetailDialogService,
              private route: ActivatedRoute,
              public constantService: ConstantService,
              private disclaimerService: DisclaimerService,
              private gtmService: GoogleTagManagerService) {
  }

  submit() {
    if (!this.isSubmittingContactInfo) {
      // @ts-ignore
      let contactId = this.route.snapshot.paramMap.params.id
      this.isSubmittingContactInfo = true;
      this.contactDetailDialogService.submit(
        contactId,
        this.contactForm.controls.name.value,
        this.contactForm.controls.email.value,
        this.contactForm.controls.location.value).subscribe(() => {
          if(this.disclaimerService.hasAcceptedCookies) {
            this.gtmService.pushTag({
              event: 'user-submitted-contact-info',
              data: 'user-submitted-contact-info'
            })
          }
        this.contactDetailDialogService.contactInformationSubmitted = true;
        this.isSubmittingContactInfo = false;
        this.contactDetailDialogService.setNpsWorkflow();
      });
    }

  }

  toggleCheckbox() {
    this.contactForm.controls.agb.setValue(!this.contactForm.controls.agb.value)
  }

  preventClickBubbleUp($event: MouseEvent) {
    $event.stopPropagation();
  }
}
