<app-header></app-header>
<div class="d-flex vw-100" >
  <div [hidden]="constantService.configuration.useCustomDialogs && contactInfoService.isModalOpen">
    <app-avatar-panel
      [contactId]="contactId"
      [currentConfig]="currentConfig"
      [contentBlocks]="contentBlocks"
    ></app-avatar-panel>
  </div>
  <div class="h-100">
    <app-presentation-area [hidden]="constantService.configuration.useCustomDialogs && contactInfoService.isModalOpen" [contentBlocks]="contentBlocks"></app-presentation-area>
  </div>
</div>
<app-footer></app-footer>

