<div class="w-100" [class.mobile-width]="mobileSizeService.isMobileSize">
  <div class="d-flex w-100">
    <div [style.opacity]="speechRecognitionInProgress ? 0 : 1" style="width: calc(100% - 60px);">
      <input
        [ngClass]="constantService.configuration.inputFieldClassName"
        class="w-100"
             (keydown.enter)="sendUserMessage(userMessageFormControl.value)"
             [formControl]="userMessageFormControl"
             type="text">
    </div>

    <button class="porsche-button" [style.bottom.px]="mobileSizeService.isMobileSize ? 3 : 12" [style.right.px]="mobileSizeService.isMobileSize ? 3 : 12" (click)="this.sendUserMessage(userMessageFormControl.value)"
      [disabled]="userMessageFormControl.invalid"
    >
      <div style="min-height: 16px; min-width: 16px;">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
             fill="currentColor" class="bi bi-mic-fill" viewBox="0 0 16 16">
          <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z"/>
        </svg>
      </div>
    </button>
    <!--
    <button class="porsche-button" (click)="buttonClicked()"
            [style.width]="speechRecognitionInProgress ? 'calc(100% - 18px)' : '46px'"
            [style.max-width]="speechRecognitionInProgress ? 'calc(100% - 18px)' : '46px'"
            [class.recording-button]="!userMessageFormControl.value && speechRecognitionInProgress">
      <div style="min-height: 16px; min-width: 16px;">
        <svg *ngIf="userMessageFormControl.value" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
             fill="currentColor" class="bi bi-mic-fill" viewBox="0 0 16 16">
          <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z"/>
        </svg>

        <svg *ngIf="!userMessageFormControl.value && !speechRecognitionInProgress" xmlns="http://www.w3.org/2000/svg"
             width="16" height="16" fill="currentColor" class="bi bi-mic"
             viewBox="0 0 16 16">
          <path
            d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5"/>
          <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3"/>
        </svg>
        <svg *ngIf="!userMessageFormControl.value && speechRecognitionInProgress" xmlns="http://www.w3.org/2000/svg"
             width="22" height="22" fill="currentColor" class="bi bi-soundwave" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
                d="M8.5 2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-1 0v-11a.5.5 0 0 1 .5-.5m-2 2a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m4 0a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m-6 1.5A.5.5 0 0 1 5 6v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m8 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m-10 1A.5.5 0 0 1 3 7v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5m12 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5"/>
        </svg>

      </div>
    </button>
    -->
  </div>
</div>
