<div
  *ngIf="conversation"
  class="chat-container d-table-cell justify-content-end align-items-end">
  <div class="p-2">
    <div class="d-flex justify-content-end">
      <fa-icon [icon]="faCaretDown" style="color: white;" size="2x" *ngIf="messageLimit > 1"
               (click)="messageLimit = 1;"></fa-icon>
    </div>
    <div class="message-container">
      <div [hidden]="!showIsProcessing">
        <div class="chat-bubble">
          <div class="typing">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
        </div>
      </div>
      <div class="d-flex" *ngFor="let message of conversation; let i = index"
           [class.justify-content-end]="message.creator === 'user'"
           [class.justify-content-start]="message.creator !== 'user'"
      >
        <div class="chat-message glas-style-border glas-style"
             *ngIf="i < messageLimit"
             [class.own-chat-message]="message.creator === 'user'">{{message.text}}</div>
      </div>
      <div class="d-flex justify-content-end">
        <fa-icon *ngIf="messageLimit <= 1" [icon]="faCaretUp" style="color: white;" size="2x" (click)="messageLimit = 9;"></fa-icon>
      </div>
      <div *ngIf="showChatSkeletonBox" class="chat-message chat-skeleton skeleton"></div>
    </div>

  </div>
</div>
