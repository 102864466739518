import {Component, Input} from '@angular/core';
import {ContentBlock} from "../../../../interfaces/contentBlock";

@Component({
  selector: 'app-mobile-duo-template',
  templateUrl: './mobile-duo-template.component.html',
  styleUrls: ['./mobile-duo-template.component.css']
})
export class MobileDuoTemplateComponent {
  private _items: ContentBlock[];
  get items(): ContentBlock[] {
    return this._items;
  }
  @Input()
  set items(value: ContentBlock[]) {
    this._items = value;
  }

}
