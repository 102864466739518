import {Component} from '@angular/core';
import {ContactDetailDialogService} from "../../services/contact-detail-dialog.service";
import {ConstantService} from "../../constants/constant.service";
import {GoogleTagManagerService} from "angular-google-tag-manager";

@Component({
  selector: 'app-contact-detail-button',
  templateUrl: './contact-detail-button.component.html',
  styleUrls: ['./contact-detail-button.component.css']
})
export class ContactDetailButtonComponent {
  constructor(public contactDetailDialogService: ContactDetailDialogService,
              public constantService: ConstantService) {
  }

  contactButtonClicked() {
    this.contactDetailDialogService.openContactDetailFlow();
  }
}
