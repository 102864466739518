<router-outlet></router-outlet>
<div *ngIf="false" style="
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  color: #FFFFFF;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center">
  Please use a desktop or maximize your browser for the best experience.
</div>
