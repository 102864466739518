import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-engine-type-indicator',
  templateUrl: './engine-type-indicator.component.html',
  styleUrls: ['./engine-type-indicator.component.css']
})
export class EngineTypeIndicatorComponent {
  @Input() contentCategory: string[];
}
