import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {ConversationMessage} from "../../interfaces/conversation.message";
import {DialogConfig} from "../../interfaces/dialog.config";
import {AvatarService} from "../../services/avatar.service";
import {ContentBlock} from "../../interfaces/contentBlock";
import {ConstantService} from "../../constants/constant.service";

@Component({
  selector: 'app-avatar-panel',
  templateUrl: './avatar-panel.component.html',
  styleUrls: ['./avatar-panel.component.css']
})
export class AvatarPanelComponent implements AfterViewInit {
  private _currentConfig: DialogConfig;
  private _contentBlocks: ContentBlock[];

  get contentBlocks(): ContentBlock[] {
    return this._contentBlocks;
  }

  @Input()
  set contentBlocks(value: ContentBlock[]) {
    this._contentBlocks = value;
  }

  get currentConfig(): DialogConfig {
    return this._currentConfig;
  }

  @Input()
  set currentConfig(value: DialogConfig) {
    this._currentConfig = value;
  }

  @Input() contactId: string;

  userMessage: string;

  constructor(public avatarService: AvatarService, public constantService: ConstantService) {

  }

  sendButtonMessage(text: string | undefined, guidedStep: string | null) {
    if (text) {
      this.userMessage = '';
      this.avatarService.showIsProcessing = true;
      this.avatarService.sendUserMessage(text, guidedStep);
    }
  }

  ngAfterViewInit(): void {
    const videoEl = document.getElementById('sm-video') as HTMLVideoElement;
    if (videoEl) {
      this.avatarService.init(videoEl, this.contactId);
    } else {
      console.log('no video element found');
    }
  }

  reconnect() {
    this.avatarService.connectToScene();
    //location.reload();
  }

  userEnteredText($event: string) {
    this.avatarService.cancelSpeaking();
    this.sendButtonMessage($event, null)
  }

  repeatQuestion($event: MouseEvent) {
    this.avatarService.repeatLastQuestion();
    $event.stopPropagation();
  }

  mutedClicked($event: MouseEvent) {
    this.avatarService.toggleMute()
    $event.stopPropagation();
  }
}
