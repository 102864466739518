import {Injectable} from '@angular/core';
import {ConversationStateTypes, Persona, Scene} from "@soulmachines/smwebsdk";
import {ConstantService} from "../constants/constant.service";
import {AsyncQueue} from "../utilities/async-queue";
import {DisclaimerService} from "./disclaimer.service";
import {WebsocketManagerService} from "./websocket-manager.service";

@Injectable({
  providedIn: 'root'
})
export class AvatarService {
  private scene: Scene;
  private persona: Persona; // Maximum number of characters per line
  speed = 2600; // Milliseconds
  highlightingInProgress = false;
  showIsProcessing: boolean;
  showReconnectButton = false;
  private interval: number;
  avatarIsSpeaking = true;
  isVideoMuted = false;
  private videoElement: HTMLVideoElement;
  private contactId: string;
  private textSnippetQueue = new AsyncQueue()
  public scrollingHighlightText = '';
  public highlightTextTopMargin = 0;
  private marginInterval: number;

  constructor(private constant: ConstantService, private disclaimerService: DisclaimerService, private websocketManager: WebsocketManagerService) {
    if (!location.origin.includes('localhost')) {
      sessionStorage.removeItem('sm-server');
      sessionStorage.removeItem('sm-session-id');
      sessionStorage.removeItem('sm-api-key');
    }
    this.websocketManager.onSystemTextResponse.subscribe(value => {
      this.onSystemTextResponse(value.message, value.ogMessage);
    })
    this.websocketManager.onNewConversationTurn.subscribe(() => {
      console.log('new Conversation Turn resetting Queue');
      this.avatarIsSpeaking = false;
      this.clearHighlighting();
      this.textSnippetQueue.resetQueue();
    });
  }

  onSystemTextResponse(message: string, ogMessage: string) {
    this.scrollingHighlightText += ogMessage;
    console.log("Message from server: " + message);
    let hasUnspokenItems = this.textSnippetQueue.hasItems(false);
    console.log('has items ', hasUnspokenItems);
    console.log('avatarIsSpeaking', this.avatarIsSpeaking);
    if (this.persona && !hasUnspokenItems && !this.avatarIsSpeaking) {
      this.textSnippetQueue.enqueue(message, true);
      console.log('message will be spoken');
      this.persona.startSpeaking(message, null, null).then(() => {
      });
    } else {
      // spoken message should not be part of the queue to prevent being spoken twice
      this.textSnippetQueue.enqueue(message);
    }
  }


  sendMessageToAvatar(message: string, guidedStep: string | null) {
    this.websocketManager.sendWebsocketMessage(message, guidedStep);
  }


  init(videoElement: HTMLVideoElement, contactId: string) {
    console.log('start init soulmachines');
    this.contactId = contactId;
    this.videoElement = videoElement;
    this.isVideoMuted = videoElement.muted;
    this.scene = new Scene({
      videoElement: videoElement,
      apiKey: this.constant.smApiKey,
      requestedMediaDevices: {},
      requiredMediaDevices: {},
      sendMetadata: {
        pageUrl: true
      }
    });
    this.scene.onDisconnectedEvent.addListener(() => {
      console.log('avatar disconnected');
      this.showReconnectButton = true;
    });
    this.connectToScene();
  }

  connectToScene() {
    console.log('connecting to scene');
    this.scene.connect()
      .then((sessionId) => this.onConnectionSuccess(sessionId))
      .catch((error) => console.log('connection failed: ', error));
    this.showReconnectButton = false;
  }

  cancelSpeaking() {
    if (this.persona) {
      this.persona.stopSpeaking();
    }
  }

  sendUserMessage(text: string, guidedStep: string | null) {
    this.textSnippetQueue.resetQueue();
    this.resetScrollingText();
    this.sendMessageToAvatar(text, guidedStep);

    // @ts-ignore
    //this.persona.conversationSend(text, {contactId: this.contactId}, null);
  }

  userSelectedCardButton(contentId: string) {
    this.sendUserMessage('###selected-' + contentId + '###', null);
  }

  private onConnectionSuccess(sessionId: string | undefined) {
    console.log('success! session id: ', sessionId);
    this.persona = new Persona(this.scene, this.scene.currentPersonaId);
    // start the video playing
    this.scene.startVideo()
      .then((videoState) => {
        console.log('started video with state: ', videoState);

        if (this.disclaimerService.hasAcceptedTerms) {
          this.repeatLastQuestion();
        } else {
          console.log('wait for user confirmation of terms');
          const subscription = this.disclaimerService.userAcceptedTermsEvent.subscribe(() => {
            this.repeatLastQuestion();
            subscription.unsubscribe();
          });
        }

      })
      .catch((error) => console.log('could not start video: ', error));

    this.scene.conversation.onConversationStateUpdated.addListener((conversationState: ConversationStateTypes) => {
      if (conversationState === 'dpSpeaking') {
        console.log('speaking event');
        this.showIsProcessing = false;
        this.avatarIsSpeaking = true;
        this.startHighlighting();
      }
      if (conversationState === 'idle') {
        console.log('idle event');
        if (this.textSnippetQueue.hasItems()) {
          this.textSnippetQueue.dequeueAll().then((items) => {
            let textToSpeak = items.join(' ');
            // @ts-ignore
            this.persona.startSpeaking(textToSpeak, {contactId: this.contactId}, null);
          })
        } else {
          this.avatarIsSpeaking = false;
          this.clearHighlighting();
        }
      }
    });
  };

  repeatLastQuestion() {
    console.log('start repeating last question');
    this.avatarIsSpeaking = false;
    this.sendUserMessage('###SYSTEM-REPEAT###', null);
  }

  private startHighlighting() {
    if (!this.highlightingInProgress) {
      this.highlightingInProgress = true;
      // this.interval = setInterval(() => {
      //   this.clearHighlighting();
      // }, this.speed);
      this.marginInterval = setInterval(() => {
        this.highlightTextTopMargin--;
      }, 140)
    }
  }

  toggleMute() {
    if (this.videoElement) {
      this.videoElement.muted = !this.videoElement.muted;
      this.isVideoMuted = this.videoElement.muted;
    }
  }

  private clearHighlighting() {
    if (this.marginInterval) {
      clearInterval(this.marginInterval); // Stop when all segments are highlighted
    }
    if (this.interval) {
      clearInterval(this.interval); // Stop when all segments are highlighted
    }
    this.highlightingInProgress = false;
  }

  private resetScrollingText() {
    this.highlightTextTopMargin = 0;
    this.scrollingHighlightText = '';
  }
}
