<div class="contact-detail-button-block">
  <div [ngClass]="constantService.configuration.subtitleClassName">Ready for a Test Drive?</div>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path d="M14.81 18.5L20 12L14.81 5.5H13.56L18.37 11.5H4V12.5H18.37L13.56 18.5H14.81Z" fill="#FBFCFF"/>
    </svg>
  </div>
  <button [ngClass]="constantService.configuration.subtitleClassName"
          class="contact-button"
          (click)="contactButtonClicked()">{{constantService.configuration.contactDetailCtaButtonLabel}}</button>
</div>
