import { Component } from '@angular/core';
import {ConstantService} from "../../constants/constant.service";
import {GoogleTagManagerService} from "angular-google-tag-manager";
import {DisclaimerService} from "../../services/disclaimer.service";
import {NavigationEnd, Router} from "@angular/router";
import {MobileSizeService} from "../../services/mobile-size.service";

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.css']
})
export class CookieBannerComponent {
  showPopup: boolean;
  constructor(public constantService: ConstantService,
              private gtmService: GoogleTagManagerService,
              private disclaimerService: DisclaimerService,
              private router: Router,
              public mobileSizeService: MobileSizeService) {
    this.showPopup = !this.disclaimerService.hasAcceptedCookies;
    if (this.disclaimerService.hasAcceptedCookies) {
      this.setupGoogleTagManager();
    }
  }

  onOk(){
    this.showPopup = false
    this.disclaimerService.acceptCookies();
    this.setupGoogleTagManager();
  }

  setupGoogleTagManager(){
    this.router.events.subscribe(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }

  onNope() {
    this.showPopup = false
    this.disclaimerService.denyCookies();
  }
}
