<div class="background-image" [ngClass]="constantService.configuration.backgroundImageClass">
  <div
    *ngIf="constantService.configuration.useCustomDialogs"
    class="background-image custom-background-filter d-flex justify-content-center align-items-center">
    <svg xmlns="http://www.w3.org/2000/svg" width="543" height="725" viewBox="0 0 543 725" fill="none">
      <path
        d="M543 362.982C543 562.909 421.445 724.982 271.5 724.982C121.555 724.982 0 562.909 0 362.982C0 163.055 121.555 0.982422 271.5 0.982422C421.445 0.982422 543 163.055 543 362.982Z"
        fill="url(#paint0_linear_3962_1406)"/>
      <defs>
        <linearGradient id="paint0_linear_3962_1406" x1="271.5" y1="0.982636" x2="271.5" y2="724.983"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#4F46E5"/>
          <stop offset="1" stop-color="#DB2777"/>
        </linearGradient>
      </defs>
    </svg>
  </div>
  <div class="blur-layer" [style.padding-top.px]="mobileSizeService.isMobileSize ? 74: 90">
    <div class="d-flex justify-content-center align-items-center">
      <div>
        <app-guided-view
          [contactId]="contactId"
          [currentConfig]="db.currentConfig"
          [contentBlocks]="db.contentBlocks"
        ></app-guided-view>
      </div>
    </div>
  </div>
</div>

<app-disclaimer></app-disclaimer>

