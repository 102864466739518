import {Component, Input} from '@angular/core';
import {faCaretDown, faCaretUp} from "@fortawesome/free-solid-svg-icons";
import {ConversationMessage} from "../../interfaces/conversation.message";

@Component({
  selector: 'app-conversation-history',
  templateUrl: './conversation-history.component.html',
  styleUrls: ['./conversation-history.component.css']
})
export class ConversationHistoryComponent {
  protected readonly faCaretDown = faCaretDown;
  protected readonly faCaretUp = faCaretUp;
  messageLimit = 1;
  @Input() showIsProcessing: boolean;
  @Input() showChatSkeletonBox: boolean = true;
  @Input() conversation: ConversationMessage[] = [];

}
